import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
    const auth = window.sessionStorage.getItem("usrToken") ? true : false; // determine if authorized, from context or however you're doing it

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return auth ? children : <Navigate to="/portal/login" />;

    // const auth = window.sessionStorage.getItem("usrToken") ? true : false; // determine if authorized, from context or however you're doing it
    // var flag = true;
    // if(auth){
    //     const permissions = JSON.parse(window.sessionStorage.getItem("usrPermissions"));
    //     flag = permissions?.find(element => element.name === permission)
    //     console.log(permission,flag);
    // }
    // // If authorized, return an outlet that will render child elements
    // // If not, return element that will navigate to login page
    // return auth ? flag ? children : <Navigate to="/admin/home" /> : <Navigate to="/admin/login" />;
}

export default PrivateRoute;