import API_ROUTES from "../Routes/APIRoutes";

export const OperationServices = {
    addHelpCenterTicket: (formData, callback) => {
        fetch(API_ROUTES.addHelpCenterTicket,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Authorization': 'Bearer ' + window.sessionStorage.getItem("usrToken")
            },
            body: formData
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
            })
            
        })
        .catch((err)=>callback(null, err));
    },
    getHelpCenterTickets: (params,callback) =>{
        let filter = params.filter;
        fetch(API_ROUTES.getHelpCenterTickets+`?filter=${encodeURI(filter)}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                callback(null,response.error);
                else
                    callback(response);
                })
                
            })
        .catch((err)=>callback(null, err));
    },
    validateRegisterToken: (params, callback) => {
        fetch(API_ROUTES.validateRegisterToken,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params) 
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
            })
            
        })
        .catch((err)=>callback(null, err));
    },
    getProviderInvitations: (params,callback) =>{
        let filter = params.filter;
        fetch(API_ROUTES.getProviderInvitations+`?filter=${encodeURI(filter)}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                callback(null,response.error);
                else
                    callback(response);
                })
                
            })
        .catch((err)=>callback(null, err));
    },
    addProviderInvitation: (params, callback) => {
        fetch(API_ROUTES.addProviderInvitation,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
            body: JSON.stringify(params) 
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
            })
            
        })
        .catch((err)=>callback(null, err));
    },
    updateProviderInvitation: (params, callback) => {
        fetch(API_ROUTES.updateProviderInvitation+`/${params.id}`, {
            method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
            body: JSON.stringify(params.body) // body data type must match "Content-Type" header
        })
        .then(res =>{
            if(res.status !== 204){
                res.json().then(response=>{
                    if(response.error)
                    callback(null,response.error);
                    else
                    callback(response);
                })
            }
            else{
                callback(res);
            } 
        }).catch((err)=> callback(null, err));
    },
    uploadProviderDocuments: (formData,rfc, callback) => {
        fetch(API_ROUTES.uploadProviderDocuments+`/${rfc}`,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
            body: formData 
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
            })
            
        })
        .catch((err)=>callback(null, err));
    },
    getProviderDocuments: (params,callback) =>{
        let filter = params.filter;
        fetch(API_ROUTES.getProviderDocuments.replace('{id}',params.id)+`?filter=${encodeURI(filter)}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                callback(null,response.error);
                else
                    callback(response);
                })
                
            })
        .catch((err)=>callback(null, err));
    },
    downloadProviderDocuments: (documentId,callback) =>{
        fetch(API_ROUTES.downloadProviderDocument+`/${documentId}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
                'accept': 'application/octet-stream'
            },
        })
        .then(res =>{
            res.blob().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
                })
                
            })
        .catch((err)=>callback(null, err));
    },
    downloadTicketEvicence: (evidenceName,callback) =>{
        console.log(API_ROUTES.downloadTicketEvicence+`/${evidenceName}`);
        fetch(API_ROUTES.downloadTicketEvicence+`/${evidenceName}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
                'accept': 'application/octet-stream'
            },
        })
        .then(res =>{
            res.blob().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
                })
                
            })
        .catch((err)=>callback(null, err));
    },
    changeTicketStatus: (params, callback) => {
        fetch(API_ROUTES.updateHelpCenterTicket+`/${params.id}`, {
            method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
            body: JSON.stringify(params.body) // body data type must match "Content-Type" header
        })
        .then(res =>{
            if(res.status !== 204){
                res.json().then(response=>{
                    if(response.error)
                    callback(null,response.error);
                    else
                    callback(response);
                })
            }
            else{
                callback(res);
            } 
        }).catch((err)=> callback(null, err));
    },
    downloadPrivacityFile: (callback) =>{
        fetch(API_ROUTES.downloadPrivacityFile, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'accept': 'application/octet-stream'
            },
        })
        .then(res =>{
            res.blob().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
                })
                
            })
        .catch((err)=>callback(null, err));
    }
    
}