import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, TextField, Tooltip } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import FloatAlert from '../../Components/Alert';
import MenuComponent from '../../Components/Menu/Menu';
import DataTable from '../../Components/DataTable/DataTable';
import { AddOutlined, CloseOutlined, FilterListOutlined, Visibility } from '@mui/icons-material';
import { InvoiceService } from '../../Services/InvoiceService';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dateFormatter from '../../Utils/DateFormatter';
import moment from 'moment-timezone';


const ProviderInvoices = () =>{
    const [loadingBackDrop,setLoadingBackDrop] = useState(false);
    const [loading,setLoading] = useState(false);
    const [invoices,setInvoices] = useState([]);
    const [invoiceDetails,setInvoiceDetails] = useState([]);
    const [filters, setShowFilters] = useState(false);
    const [openDetails, setOpenDetails] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(false);
    const [appliedFilter, setAppliedFilter] = useState(false);
    const [dateType,setDateType] = useState("FC");
    let initialFilters = {
        from: '',
        to: '',
    }

    let[dateFilter,setDateFilter] = useState(initialFilters);
    const userId = window.sessionStorage.getItem('userId');
    let filter = `{
        "order": "creationDate DESC",
        "where": {
            "createByUserId": ${userId}
        }
    }`;

    useEffect(() =>{
        getInvoicesByProvider(filter);
    },[])

    const getInvoicesByProvider = (filter) =>{
        setLoadingBackDrop(true);
        let params = {
            filter: filter
        }
        InvoiceService.getInvoicesByProvider(params,(response,error) =>{
            setLoadingBackDrop(false);
            if(response){
                setInvoices(response);
            }

        });
    }

    const showInvoiceDetails = (event,cellValues) =>{
        setSelectedInvoice(cellValues.row);
        setLoadingBackDrop(true);
        const invoiceId = cellValues.row.id;
        InvoiceService.getInvoiceDetails(invoiceId,(response,error) =>{
            setLoadingBackDrop(false);
            if(response){
                setInvoiceDetails(response);
            }
            setOpenDetails(true);
        })

    }

    function filterDate (){
        return(
            <Fragment>
                <Button onClick={() =>{
                    setShowFilters(true);
                }} startIcon={<FilterListOutlined />} >FILTROS</Button>
                {
                    appliedFilter ?
                    <Tooltip title="Limpiar Filtro">
                        <IconButton onClick={() =>{
                            setLoadingBackDrop(true);
                            setAppliedFilter(false);
                            getInvoicesByProvider(filter);
                            setDateFilter(initialFilters);
                        }} aria-label="clear" size="small" color="primary">
                            <CloseOutlined />
                        </IconButton>
                    </Tooltip>
                    : ''
                }
                {/* <Button  onClick={() =>{
                    setLoadingBackDrop(true);
                    getValidations(filter);
                    setDateFilter(initialFilters);
                }} startIcon={<CloseOutlined />}>Limpiar</Button> */}

            </Fragment>
        )
    }


    const columns = [
        { field: 'id', headerName: 'ID', width: 100},
        { field: 'folio', headerName: 'Folio', width: 100,valueGetter: (params) =>{
            return params.row.serie + params.row.folio;
        },
        },
        { field: 'sapInvoiceId', headerName: 'Factura SAP', width: 150},
        { field: 'emisorRfc', headerName: 'RFC Emisor', width: 150},
        { field: 'emisor', headerName: 'Emisor', width: 150},
        { field: 'documentType', headerName: 'Tipo', width: 150,
            valueGetter: (params) =>{
                let type = "";
                switch(params.row.documentType){
                    case 'I':
                        type = "Ingreso"
                        break;
                    case 'E':
                        type = "Egreso"
                        break;
                }
                return type;
            },
        },
        { field: 'fiscalFolio', headerName: 'UUID', width: 350},
        { field: 'currency', headerName: 'Moneda', width: 100},
        { field: 'stampDate', headerName: 'F. Timbrado', width: 200,
            valueGetter: (params) =>{
                return params.row.stampDate.substring(0,19).replace('T',' ');
                // return moment.tz(params.row.stampDate,'America/Mexico_City').format("YYYY-MM-DD HH:mm:ss");
            },
        },
        { field: 'subtotal', headerName: 'SubTotal', width: 100},
        { field: 'iva', headerName: 'IVA', width: 100},
        { field: 'deteinedTaxes', headerName: 'Imp. Retenidos', width: 150},
        { field: 'total', headerName: 'Total', width: 150},
        { field: 'F. Alta', headerName: 'Fecha Alta', width: 170,
            valueGetter: (params) =>{
                return params.row.creationDate.substring(0,10).replace('T',' ');
                // return moment.tz(params.row.creationDate,'America/Mexico_City').format("YYYY-MM-DD HH:mm:ss");
            },
        },
        { field: 'dueDate', headerName: 'F. Vencimiento', width: 170,
            valueGetter: (params) =>{
                return params.row.dueDate?.substring(0,10);
            },
        },
        { field: 'status', headerName: 'Estatus', width: 170,
            valueGetter: (params) =>{
                let status = "";
                switch(params.row.status){
                    case 0:
                        status = "CANCELADA"
                        break;
                    case 1:
                        status = "PENDIENTE"
                        break;
                    case 2:
                        status = "PAGADA"
                        break;
                    
                }
                return status
            },
        },
         {
            field: '',
            headerName: 'Opciones',
            description: 'This column has a value getter and is not sortable.',
            width: 160,
            renderCell: (cellValues) => {
                return (
                    <React.Fragment>
                         <Tooltip title="Ver detalles">
                            <IconButton aria-label="edit" 
                                onClick={(event) => {
                                    showInvoiceDetails(event,cellValues);
                                }}
                                >
                                    <Visibility />
                            </IconButton>
                        </Tooltip>
                        
                    </React.Fragment>
                );
            }
            
        },
    ];

    const getClassName = (params) => {
        if (params.field === "status") {
          return params.value === "PENDIENTE" ? "cell-orange" : params.value == 'PAGADA' ? "cell-green" : "cell-red";
        }
        return "";
    }


    const columnsDetails = [
        // { field: 'id', headerName: 'ID', width: 100},
        { field: 'orderNumber', headerName: 'No. Orden', width: 100},
        { field: 'position', headerName: 'Posición', width: 100},
        { field: 'orderType', headerName: 'T. Orden', width: 150},
        { field: 'quantity', headerName: 'Cantidad', width: 100},
        { field: 'identificationNumber', headerName: 'No. Identificación', width: 150},
        { field: 'keyProductService', headerName: 'Clave', width: 150},
        { field: 'description', headerName: 'Descripción', width: 200},
        { field: 'unitPrice', headerName: 'P. Unitario', width: 100},
        { field: 'importe', headerName: 'Importe', width: 100},
        { field: 'discount', headerName: 'Descuento', width: 150},
        { field: 'iva', headerName: 'IVA', width: 150}
        
        
    ];

    const handleCloseFilters = (e,reason) =>{
        if(!reason){
            setDateFilter(initialFilters);
            setShowFilters(false);
        }
    }

    const applyFilter = () =>{
        setShowFilters(false);
        setLoadingBackDrop(true);
        let from = dateFormatter(dateFilter.from['$d'],'-') + ' 00:00:00';
        let to = dateFormatter(dateFilter.to['$d'],'-') + ' 23:59:59';
        console.log(from,to);
        const dateParam = dateType == 'FC' ? 'creationDate' : 'stampDate';
        let filter = `{"where": {"and": [{"createByUserId": ${userId}},{"${dateParam}": {"between": ["${from}", "${to}"]}}]}}`;
        // let filter = `{"where": {"${dateParam}": {"between": ["${from}", "${to}"]}}}`;
        getInvoicesByProvider(filter);
        setAppliedFilter(true);
    }

    return (
        <Fragment>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingBackDrop}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
            <FloatAlert/>
            <MenuComponent permissionName={"Facturas Proveedor"}>
                <DataTable columns={columns} rows={invoices} filterDate={filterDate} getClassName={getClassName}/>
            </MenuComponent>
            <Dialog disableEscapeKeyDown={true} open={filters} onClose={handleCloseFilters} fullWidth>
                <DialogTitle>Filtrar Fecha</DialogTitle>
                <DialogContent>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '& > :not(style)': { m: 1 }
                    }}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1},
                            }}
                            noValidate
                            autoComplete="off">

                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Filtrar Fecha</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={dateType}
                                    onChange={(e) =>{setDateType(e.target.value)}}
                                >
                                    <FormControlLabel value="FC" control={<Radio />} label="F. Creación" />
                                    <FormControlLabel value="FT" control={<Radio />} label="F. Timbrado" />
                                </RadioGroup>
                            </FormControl>
                            <Box sx={{flexGrow: 1}}></Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="F. Inicio"
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    value={dateFilter.from}
                                    onChange={(newValue) => {
                                        setDateFilter({...dateFilter,from: newValue});
                                    }}
                                    inputFormat="DD-MM-YYYY"
                                    renderInput={(params) => <TextField required size="small" {...params} />}
                                />
                                <Box sx={{flexGrow: 1}}></Box>
                            </LocalizationProvider>
                            <Box sx={{flexGrow: 1}}></Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="F. Fin"
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    minDate={dateFilter.from}
                                    disabled={!dateFilter.from}
                                    value={dateFilter.to}
                                    onChange={(newValue) => {
                                        setDateFilter({...dateFilter,to: newValue});
                                    }}
                                    inputFormat="DD-MM-YYYY"
                                    renderInput={(params) => <TextField required size="small" {...params} />}
                                />
                            </LocalizationProvider>
                            
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                <Button startIcon={<CloseOutlined />} onClick={handleCloseFilters} size="small" variant="outlined">Cerrar</Button>
                <Button startIcon={<FilterListOutlined />} onClick={applyFilter} disabled={!(dateFilter.from && dateFilter.to)} size="small" variant="outlined">Filtrar</Button>
                </DialogActions>
            </Dialog>

            <Dialog disableEscapeKeyDown={true} open={openDetails} onClose={() =>{setOpenDetails(!openDetails)}} fullWidth maxWidth={'xl'}>
                <DialogTitle>Conceptos factura {selectedInvoice.serie}{selectedInvoice.folio}  ({selectedInvoice.fiscalFolio})</DialogTitle>
                <DialogContent>
                    <DataTable columns={columnsDetails} rows={invoiceDetails}/>
                </DialogContent>
                <DialogActions>
                <Button startIcon={<CloseOutlined />} onClick={() =>{setOpenDetails(!openDetails)}} size="small" variant="outlined">Cerrar</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default ProviderInvoices;