import { LockOutlined } from "@mui/icons-material";
import { Avatar, Backdrop, Box, Button, CardMedia, CircularProgress, Container, CssBaseline, Grid, Link, TextField, ThemeProvider, Typography, createTheme } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserService } from "../../Services/UserService";
import FloatAlert from "../../Components/Alert";
import logo from '../../logo.png'


const Login = () =>{

    const history = useNavigate();
    let dispatch = useDispatch();

    let initialValue = {
        username: '',
        password: ''
    }

    const [value, setValue] = useState(initialValue);
    
    let [loading, setLoading] = useState(false);
    let [open, setOpen] = useState(false);

    function Copyright(props) {
        return (
          <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="http://reacsa.com.mx/" target="_blank">
              Portal de Proveedores
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        );
    }
      
    const theme = createTheme();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        let params = {
            email: value.username,
            password: value.password
        };
        
        UserService.login(params,(response,error) =>{
            setLoading(false);
            if(error){
                let props = {open: true,
                    severity: 'error',
                    message: error.message}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}})
            }
            else{
                if(response.user && response.token){
                    dispatch({type: "INITIAIZE_COMPONENTS"});
                    dispatch({type: "UPDATE_USER_DATA",idx:"username",value: value.username});
                    window.sessionStorage.setItem("usrToken",response.token);
                    window.sessionStorage.setItem("userId",response.user.id);
                    window.sessionStorage.setItem("username",value.username);
                    UserService.getUserById({userId: response.user.id, filter: `{"include":["person"]}`},(response,error) =>{
                        if(response && !error){
                            dispatch({type: "UPDATE_USER_DATA",idx:"username",value: value.username});
                            dispatch({type: "UPDATE_USER_DATA",idx:"name",value: response.person?.fullName});
                            dispatch({type: "UPDATE_USER_DATA",idx:"changePassword",value: response.changePassword});
                            window.sessionStorage.setItem("userRoleId",response.roleId);
                            let params = {
                                filter: `{"where":{"roleId":${Number(response.roleId)}}, "include":["applicationOperation"]}`
                            }
                            UserService.getApplicationAuthorizations(params,(permissions,error) =>{
                                dispatch({type: "SET_PERMISSIONS",idx:"permissions",value: permissions});
                            });
                        }
                        history('/portal/home');
                    });
                    
                    
                }
            }
        });
    };

    const forgotPass = () =>{
        history('/portal/forgotPassword');
    }


    return (
        <ThemeProvider theme={theme}>
            <FloatAlert/>
    
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
          </Backdrop>
            <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                        sx={{
                        marginTop: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        }}
                    >
                        <img src={logo} alt="Logo" height={"200px"} width={"200px"}/>
                        {/* <CardMedia
                                component="img"
                                height="100"
                                image={logo}
                                alt="Paella dish"
                            /> */}
                    </Box>
            <Box
                sx={{
                // marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlined />
                </Avatar>
                <Typography component="h1" variant="h5">
                Iniciar sesión
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Usuario"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={value.username}
                        onChange={(e) =>{setValue({...value, username: e.target.value})}}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={value.password}
                        onChange={(e) =>{setValue({...value, password: e.target.value})}}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={!(value.username.length > 0 && value.password.length > 0)}
                    >
                        Iniciar Sesión
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Button onClick={() =>{forgotPass()}}  variant="text">¿Olvidó su contraseña?</Button>
                        </Grid>
                    </Grid>
                    
                    {/* <Grid container>
                        <Grid item justifyContent={"center"} alignItems={"center"}>
                            
                        </Grid>
                    </Grid> */}
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
        );

}

export default Login;