import { API_URL } from "../Path";

const API_ROUTES = {

    login: API_URL + '/users/login',
    recoverPass: API_URL + '/users/recoverPass',
    //USERS
    getUser: API_URL +'/users',
    updatePassword: API_URL +'/users/updatePassword',
    addUser: API_URL +'/users',
    changeUserStatus: API_URL +'/users/changeStatus',
    validateToken: API_URL + '/users/validateToken',
    //HELP CENTER
    addHelpCenterTicket: API_URL + '/help-center-tickets',
    getHelpCenterTickets: API_URL + '/help-center-tickets',
    updateHelpCenterTicket: API_URL + '/help-center-tickets',
    downloadTicketEvicence: API_URL + '/help-center-tickets/downloadEvidece',
    //VALIDATE PROVIDER REGISTER TOKEN
    validateRegisterToken: API_URL + '/provider-invitations/validateToken',
    getProviderInvitations: API_URL + '/provider-invitations',
    addProviderInvitation: API_URL + '/provider-invitations',
    updateProviderInvitation: API_URL + '/provider-invitations',
    //CATALOGS
    getSociecities: API_URL + '/catalog/sociecityCatalog',
    getFiscalRegulations: API_URL + '/catalog/fiscalRegulationCatalog',
    getProviderTypes: API_URL + '/catalog/providerTypeCatalog',
    getOperationTypes: API_URL + '/catalog/operationTypeCatalog',
    getBankKeys: API_URL + '/catalog/bankKeyCatalog',
    getCountries: API_URL + '/catalog/countries',
    getProvinces: API_URL + '/catalog/provinces',
    getCurrencies: API_URL + '/catalog/currencies',
    getPaymentConditions: API_URL + '/catalog/paymentConditions',
    getDepartments: API_URL + '/catalog/departments',
    getJobs: API_URL + '/catalog/jobs',
    //PROVIDERS
    addProvider: API_URL + '/providers',
    updateProvider: API_URL + '/providers',
    getProviders: API_URL + '/providers',
    validateProviders: API_URL + '/providers/validate',
    uploadProviderDocuments: API_URL + '/providers/uploadDocuments',
    getRoles: API_URL + '/roles',
    getApplicationOperations: API_URL +'/application-operations',
    getApplicationAuthorizations: API_URL + '/application-authorizations',
    addApplicationAuthorizationToRole: API_URL + '/application-authorizations',
    getProviderDocuments: API_URL + '/providers/{id}/provider-documents',
    downloadProviderDocument: API_URL + '/providers/downdloadDocument',
    syncronizeProviders: API_URL + '/providers/sincronize',
    downloadPrivacityFile: API_URL + '/providers/downdloadPrivacityFile',
    refuseProvider: API_URL + '/providers/refuseProvider',
    //ORDERS
    getOrders: API_URL + '/orders/getOrders',
    uploadProviderInvoice: API_URL + '/orders/uploadInvoice/',
    //INVOICES
    getInvoicesByProvider: API_URL + '/invoices',
    getInvoiceDetails: API_URL + '/invoices/{id}/concepts',
    sendToSap: API_URL + '/invoices/sendToSap'

}

export default API_ROUTES;