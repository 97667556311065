const initialState = {
    alert:{
        open: false,
        severity: 'success',
        message: ''
    },
    confirmAlert:{
        open: false,
    },
    menu:{
        selectedItem: 0,
        name: ""
    }
}

const componentReducer = (state = initialState, action) => {
    switch(action.type){
        case 'UPDATE_ALERT_VALUES':
            state = {...state};
            state[action.idx] = action.value;
            return state;
        case 'UPDATE_CONFIRM_ALERT_VALUES':
            state = {...state};
            state[action.idx] = action.value;
            return state;
        case 'RESET_ALERT':
            state = {...state};
            state[action.idx] = initialState.alert;
            return state;
        case 'SELECTED_MENU_ITEM':
            state = {...state};
            state[action.idx] = action.value;
            return state;
        case 'INITIAIZE_COMPONENTS':
            state = initialState;
            return state;
        default:
            return state;
    }
}

export default componentReducer;