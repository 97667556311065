import React, { Fragment, useEffect, useState } from "react";
import Menu from "../../Components/Menu/Menu";
import { Backdrop, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, IconButton, Paper, TextField, Tooltip, styled } from "@mui/material";
import FloatAlert from "../../Components/Alert";
import DataTable from "../../Components/DataTable/DataTable";
import {CheckOutlined, CloseOutlined, DownloadDoneOutlined, DownloadOutlined, Visibility } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { OperationServices } from "../../Services/OperationService";

const HelpCenterAdmin = () =>{

    const dispatch = useDispatch();
    let userId = Number(window.sessionStorage.getItem("userId"));
    const {name} = useSelector(store => store.userReducer);

    const initialValue = {
        id: 0,
        subject: "",
        userId: userId,
        comments: "",
        answer: "",
        status: 0
    }

    const [loading,setLoading] = useState(false);
    const [loadingBackDrop,setLoadingBackDrop] = useState(false);
    const [open,setOpen] = useState(false);
    const [value,setValue] = useState(initialValue);
    const [btnUploadText, setBtnUploadText] = useState("Cargar archivos");
    const [files,setFiles] = useState(null);
    const [chipData, setChipData] = React.useState([]);

    const [tickets, setTickets] = useState([]);

    useEffect(() =>{
        getHelpCenterTickets();
    },[])

    const getHelpCenterTickets = () =>{
        setLoadingBackDrop(true);
        let params = {
            filter: `{"order": "creationDate DESC","include":["user"]}`
        }
        OperationServices.getHelpCenterTickets(params, (response,error) =>{
            setLoadingBackDrop(false);
            if(response?.length){
                setTickets(response);
            }
        })
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'subjetc', headerName: 'Asunto', width: 200,
            valueGetter: (params) =>{
                return params.row.subject;
            },
        },
        { field: 'Fecha', headerName: 'Fecha', width: 170,
            valueGetter: (params) =>{
                return params.row.creationDate.substring(0,19).replace('T',' ');
                // return moment.tz(params.row.creationDate,'America/Mexico_City').format("YYYY-MM-DD HH:mm");
            },
        },
        { field: 'user', headerName: 'Usuario', width: 200,
            valueGetter: (params) =>{
                return params.row.user.email;
            },
        },
        
        { field: 'status', headerName: 'Estatus', width: 170,
            valueGetter: (params) =>{
                let status = "";
                switch(params.row.status){
                    case 1:
                        status = "PENDIENTE"
                        break;
                    case 2:
                        status = "EN REVISIÓN"
                        break;
                    case 4:
                        status = "SOLUCIONADO"
                        break;
                    case 0:
                        status = "CERRADO"
                        break;
                }
                return status
            },
        },
        { field: 'solvedBy', headerName: 'Solucionado Por', width: 200},
        { field: 'solvedDate', headerName: 'F. Solución', width: 200,
            valueGetter: (params) =>{
                return params.row.solvedDate?.replace('T', ' ').substring(0,16);
            },
        },
        {
            field: '',
            headerName: 'Opciones',
            description: 'This column has a value getter and is not sortable.',
            width: 160,
            renderCell: (cellValues) => {
                return (
                    <React.Fragment>
                         <Tooltip title="Revisar Ticket">
                            <IconButton aria-label="edit" 
                                onClick={(event) => {
                                    showDetails(event,cellValues);
                                }}
                                >
                                    <Visibility />
                            </IconButton>
                        </Tooltip>
                        
                    </React.Fragment>
                );
            }
            
        },
    ];

    const getClassName = (params) => {
        if (params.field === "status") {
          return params.value === "PENDIENTE" ? "cell-yellow" : params.value == 'EN REVISIÓN' ? "cell-orange" : "cell-green";
        }
        return "";
    }

    const solvedTicket = () =>{
        setLoading(true);
        let params = {
            id: value.id,
            body:{
                status: 4,
                answer: value.answer,
                solvedBy: name
            }
        }
        OperationServices.changeTicketStatus(params,(response,error) =>{
            setLoading(false);
            if(response && !error){
                let props = {open: true,
                    severity: 'success',
                    message: `Registro actualizado correctamente.`};
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                handleClose();
                getHelpCenterTickets();
            }
            else{
                let props = {open: true,
                    severity: 'error',
                    message: error.message}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }
        });
        

    };

    const handleClose = (e,reason) =>{
        if(!reason){
            setOpen(false);
            setValue(initialValue);
            setFiles(null);
            //setBtnUploadText("Cargar archivos")
            setChipData([]);
        }
    }

    const handleClickOpen = () =>{
        setOpen(true);
    }

    const showDetails = (e,cellValues) => {
        setValue({...value,id:cellValues.row.id, subject:cellValues.row.subject,comments: cellValues.row.comments,status: cellValues.row.status,answer: cellValues.row.answer});
        if(cellValues.row.status == 1){
            let params = {
                id: cellValues.row.id,
                body:{
                    status: 2
                }
            }
            OperationServices.changeTicketStatus(params,(response,error) =>{
                setValue({...value,id:cellValues.row.id, subject:cellValues.row.subject,comments: cellValues.row.comments,status: 2});
                getHelpCenterTickets();
            });
        }
        let chipArray = [];
        for (const file of cellValues.row.evidencePath) {
            let filePath = file.split('/');
            if(filePath.length > 1){
                let data = {
                    label: filePath[(filePath.length - 1)]
                }
                chipArray.push(data);
            }
            
        }
        //setBtnUploadText(target.files[0].name)
        
        
        setChipData(chipArray);
        setOpen(true);
        //setSolvedEvidence({...solvedEvidence,image: target.files[0]});
    };

    const ListItem = styled('li')(({ theme }) => ({
        margin: theme.spacing(0.5),
    }));

    const downloadEvidence = (evidenceName) =>{
        console.log(evidenceName);
        OperationServices.downloadTicketEvicence((evidenceName),(response) =>{
            var a = document.createElement('a');
            var url = window.URL.createObjectURL(response);
            a.href = url;
            a.download = evidenceName;
            document.body.append(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);

        })

        
    }

    return(
        <Fragment>
            <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loadingBackDrop}
                >
                    <CircularProgress color="inherit" />
            </Backdrop>
            <FloatAlert/>
            <Menu permissionName={"Centro ayuda (Administrador)"}>
                <DataTable rows={tickets} columns={columns} getClassName={getClassName}/>
            </Menu>

            <Dialog disableEscapeKeyDown={true} open={open} onClose={handleClose}>
                <DialogTitle>Ticket</DialogTitle>
                <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <TextField fullWidth variant="standard" inputProps={{readOnly: true}} required label="Asunto" value={value.subject} onChange={(e) =>{setValue({...value,subject:e.target.value})}}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField multiline rows={2} fullWidth inputProps={{readOnly: true}} variant="standard" required label="Comentarios" value={value.comments} onChange={(e) =>{setValue({...value,comments:e.target.value})}}/>
                    </Grid>
                    {
                        chipData.length ? 
                        <Grid item xs={12} sm={12}>
                            <Paper
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    listStyle: 'none',
                                    p: 0.5,
                                    m: 0,
                                }}
                                component="ul"
                                >
                                {chipData.map((data,i) => {
                                    let icon;

                                    return (
                                    <ListItem key={i}>
                                        <Chip
                                            icon={<DownloadOutlined/>}
                                            label={data.label}
                                            onClick={() =>{downloadEvidence(data.label)}}
                                        />
                                    </ListItem>
                                    );
                                })}
                            </Paper>
                        </Grid>
                        : ""
                    }
                    <Grid item xs={12} sm={12}>
                        <TextField multiline rows={2} fullWidth variant="standard" inputProps={{readOnly: !value.status == 2}} required label="Solución" value={value.answer} onChange={(e) =>{setValue({...value,answer:e.target.value})}}/>
                    </Grid>
                </Grid>
                </DialogContent>
                <DialogActions>
                <Button disabled={loading} startIcon={<CloseOutlined />} onClick={handleClose} size="small" variant="outlined">Cancelar</Button>
                {
                    value.status == 2 ?
                    <LoadingButton
                        color="success"
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<CheckOutlined />}
                        variant="outlined"
                        loadingIndicator={<CircularProgress color="success" size={16}/>}
                        size="small"
                        disabled={!value.answer.length}
                        onClick={solvedTicket}
                    >
                        Solucionado
                    </LoadingButton>
                    : ""
                }
                
                </DialogActions>
            </Dialog>
        </Fragment>
    )

}

export default HelpCenterAdmin;