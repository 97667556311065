import React, { Fragment, useEffect, useState } from "react";
import Menu from "../../Components/Menu/Menu";
import { Backdrop, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, IconButton, Paper, TextField, Tooltip, styled } from "@mui/material";
import FloatAlert from "../../Components/Alert";
import DataTable from "../../Components/DataTable/DataTable";
import { AddOutlined, AttachFileOutlined, CloseOutlined, DownloadOutlined, Save, Visibility } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { OperationServices } from "../../Services/OperationService";
import moment from "moment-timezone";

const HelpCenterIndex = () =>{

    const dispatch = useDispatch();
    let userId = Number(window.sessionStorage.getItem("userId"));

    const initialValue = {
        subject: "",
        userId: userId,
        comments: ""
    }

    const [loading,setLoading] = useState(false);
    const [loadingBackDrop,setLoadingBackDrop] = useState(false);
    const [open,setOpen] = useState(false);
    const [openDetails,setOpenDetails] = useState(false);
    const [value,setValue] = useState(initialValue);
    const [btnUploadText, setBtnUploadText] = useState("Cargar archivos");
    const [files,setFiles] = useState(null);
    const [chipData, setChipData] = React.useState([]);

    const [tickets, setTickets] = useState([]);

    useEffect(() =>{
        getHelpCenterTickets();
    },[])

    const getHelpCenterTickets = () =>{
        setLoadingBackDrop(true);
        let params = {
            filter: `{"order": "creationDate DESC","where": {"userId" : ${userId}}}`
        }
        OperationServices.getHelpCenterTickets(params, (response,error) =>{
            setLoadingBackDrop(false);
            if(response?.length){
                setTickets(response);
            }
        })
    }

    const showDetails = (e,cellValues) => {
        setValue({...value,id:cellValues.row.id, subject:cellValues.row.subject,comments: cellValues.row.comments,status: cellValues.row.status,answer: cellValues.row.answer});
        let chipArray = [];
        for (const file of cellValues.row.evidencePath) {
            let filePath = file.split('/');
            if(filePath.length > 1){
                let data = {
                    label: filePath[(filePath.length - 1)]
                }
                chipArray.push(data);
            }
            
        }
        //setBtnUploadText(target.files[0].name)
        
        
        setChipData(chipArray);
        setOpenDetails(true);
        //setSolvedEvidence({...solvedEvidence,image: target.files[0]});
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'subjetc', headerName: 'Asunto', width: 200,
            valueGetter: (params) =>{
                return params.row.subject;
            },
        },
        { field: 'Fecha', headerName: 'Fecha', width: 170,
            valueGetter: (params) =>{
                return params.row.creationDate.substring(0,19).replace('T',' ');
                // return moment.tz(params.row.creationDate,'America/Mexico_City').format("YYYY-MM-DD HH:mm");
            },
        },
        { field: 'status', headerName: 'Estatus', width: 170,
            valueGetter: (params) =>{
                let status = "";
                switch(params.row.status){
                    case 1:
                        status = "ENVIADO"
                        break;
                    case 2:
                        status = "EN REVISIÓN"
                        break;
                    case 4:
                        status = "SOLUCIONADO"
                        break;
                    case 0:
                        status = "CERRADO"
                        break;
                }
                return status
            },
        },
        { field: 'solvedDate', headerName: 'F. Solución', width: 200,
            valueGetter: (params) =>{
                return params.row.solvedDate?.replace('T', ' ').substring(0,16);
            },
        },
        {
            field: '',
            headerName: 'Opciones',
            description: 'This column has a value getter and is not sortable.',
            width: 160,
            renderCell: (cellValues) => {
                return (
                    <React.Fragment>
                         <Tooltip title="Ver detalles">
                            <IconButton aria-label="edit" 
                                onClick={(event) => {
                                    showDetails(event,cellValues);
                                }}
                                >
                                    <Visibility />
                            </IconButton>
                        </Tooltip>
                        
                    </React.Fragment>
                );
            }
            
        },
    ];

    const getClassName = (params) => {
        if (params.field === "status") {
          return params.value === "ENVIADO" ? "cell-yellow" : params.value == 'EN REVISIÓN' ? "cell-orange" : "cell-green";
        }
        return "";
    }

    const addTicket = () =>{
        setLoading(true);
        const formData = new FormData();
        if(files){
            files.forEach(file => {
                formData.append("file", file);    
            });
        }
        formData.append("subject", value.subject);
        formData.append("comments", value.comments);
        formData.append("userId", Number(value.userId));
        OperationServices.addHelpCenterTicket(formData,(response,error) =>{
            setLoading(false);
            if(response && !error){
                let props = {open: true,
                    severity: 'success',
                    message: `Registro agregado correctamente.`};
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                handleClose();
                getHelpCenterTickets();
            }
            else{
                let props = {open: true,
                    severity: 'error',
                    message: error.message}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }

        });

    };

    const handleClose = (e,reason) =>{
        if(!reason){
            setOpen(false);
            setValue(initialValue);
            setFiles(null);
            //setBtnUploadText("Cargar archivos")
            setChipData([]);
        }
    }

    const handleCloseDetails = (e,reason) =>{
        if(!reason){
            setOpenDetails(false);
            setValue(initialValue);
            setChipData([]);
        }
    }

    const handleClickOpen = () =>{
        setOpen(true);
    }

    const handleCapture = ({ target }) => {
        //setBtnUploadText(target.files[0].name)
        let arrayFile = [];
        let chipArray = [];
        for (let index = 0; index < target.files.length; index++) {
            const element = target.files[index];
            let size = (element.size / 1000000)
            if(size <= 3){
                arrayFile.push(element);
                let fileName = element.name;
                if(fileName.length > 20){
                    fileName = fileName.substring(0,20) + '...';
                }
                let fileObj = {
                    key: index,
                    label: fileName
                }
                chipArray.push(fileObj);
            }
            else{
                chipArray = [];
                arrayFile = [];
                let props = {open: true,
                    severity: 'error',
                    message: `El archivo ${element.name} excede los 3 MB.`}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                break;
            }
        }
        setChipData(chipArray);
        setFiles(arrayFile);
        //setSolvedEvidence({...solvedEvidence,image: target.files[0]});
    };

    const ListItem = styled('li')(({ theme }) => ({
        margin: theme.spacing(0.5),
    }));

    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };

    return(
        <Fragment>
            <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loadingBackDrop}
                >
                    <CircularProgress color="inherit" />
            </Backdrop>
            <FloatAlert/>
            <Menu permissionName={"Centro de ayuda"}>
                <DataTable rows={tickets} columns={columns} getClassName={getClassName}/>
                <Box sx={{flexGrow: 1}}></Box>
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                    <Fab  color="primary" aria-label="add" onClick={() =>{handleClickOpen()}}
                        sx={{position: 'fixed',bottom: 16, right: 16,}}>
                        <AddOutlined />
                    </Fab>
                </Box>
            </Menu>

            <Dialog disableEscapeKeyDown={true} open={open} onClose={handleClose}>
                <DialogTitle>Nuevo Ticket</DialogTitle>
                <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <TextField fullWidth variant="standard" required label="Asunto" value={value.subject} onChange={(e) =>{setValue({...value,subject:e.target.value})}}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField multiline rows={2} fullWidth variant="standard" required label="Comentarios" value={value.comments} onChange={(e) =>{setValue({...value,comments:e.target.value})}}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button variant="contained" component="label">
                            {btnUploadText}
                            <input hidden accept="image/*, application/pdf" multiple type="file" onChange={handleCapture} />
                        </Button>
                    </Grid>
                    {
                        chipData.length ? 
                        <Grid item xs={12} sm={12}>
                            <Paper
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    listStyle: 'none',
                                    p: 0.5,
                                    m: 0,
                                }}
                                component="ul"
                                >
                                {chipData.map((data) => {
                                    let icon;

                                    return (
                                    <ListItem key={data.key}>
                                        <Chip
                                            icon={icon}
                                            label={data.label}
                                            onDelete={handleDelete(data)}
                                        />
                                    </ListItem>
                                    );
                                })}
                            </Paper>
                        </Grid>
                        : ""
                    }
                </Grid>
                </DialogContent>
                <DialogActions>
                <Button disabled={loading} startIcon={<CloseOutlined />} onClick={handleClose} size="small" variant="outlined">Cancelar</Button>
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<Save />}
                    variant="outlined"
                    loadingIndicator={<CircularProgress color="primary" size={16}/>}
                    size="small"
                    //disabled={!value.name.length}
                    onClick={addTicket}
                >
                    Guardar
                </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog disableEscapeKeyDown={true} open={openDetails} onClose={handleCloseDetails}>
                <DialogTitle>Ticket</DialogTitle>
                <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <TextField fullWidth variant="standard" inputProps={{readOnly: true}} required label="Asunto" value={value.subject} onChange={(e) =>{setValue({...value,subject:e.target.value})}}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField multiline rows={2} fullWidth inputProps={{readOnly: true}} variant="standard" required label="Comentarios" value={value.comments} onChange={(e) =>{setValue({...value,comments:e.target.value})}}/>
                    </Grid>
                    {
                        chipData.length ? 
                        <Grid item xs={12} sm={12}>
                            <Paper
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    listStyle: 'none',
                                    p: 0.5,
                                    m: 0,
                                }}
                                component="ul"
                                >
                                {chipData.map((data,i) => {
                                    let icon;

                                    return (
                                    <ListItem key={i}>
                                        <Chip
                                            icon={<AttachFileOutlined/>}
                                            label={data.label}
                                            // onClick={() =>{downloadEvidence(data.label)}}
                                        />
                                    </ListItem>
                                    );
                                })}
                            </Paper>
                        </Grid>
                        : ""
                    }
                    <Grid item xs={12} sm={12}>
                        <TextField multiline rows={2} fullWidth variant="standard" inputProps={{readOnly: true}} required label="Solución" value={value.answer} onChange={(e) =>{setValue({...value,answer:e.target.value})}}/>
                    </Grid>
                </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} startIcon={<CloseOutlined />} onClick={handleCloseDetails} size="small" variant="outlined">Cerrar</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )

}

export default HelpCenterIndex;