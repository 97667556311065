import { Backdrop, Box, Button, Checkbox, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControlLabel, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import FloatAlert from "../../Components/Alert";
import DataTable from "../../Components/DataTable/DataTable";
import MenuComponent from "../../Components/Menu/Menu";
import { CancelOutlined, CheckBoxOutlineBlankOutlined, CheckBoxOutlined, DownloadOutlined, SyncOutlined, Visibility } from "@mui/icons-material";
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import { UserService } from "../../Services/UserService";
import ConfirmAlert from "../../Components/ConfirmAlert";
import { useDispatch, useSelector } from "react-redux";
import { OperationServices } from "../../Services/OperationService";

const ProviderIndex = () =>{

    const dispatch = useDispatch();
    const [loadingBackDrop,setLoadingBackDrop] = useState(false);
    const [providers,setProviders] = useState([]);
    const [openConfirm,setOpenConfirm] = useState(false);
    const [openRefuseConfirm,setOpenRefuseConfirm] = useState(false);
    const [selectedProvider,setSelectedProvider] = useState({});
    const [openDetails,setOpenDetails] = useState(false);
    const [providerDocuments,setProviderDocuments] = useState([]);
    const [checked,setChecked] = useState(true);

    const {permissions} = useSelector(store => store.userReducer);
    const changeStatusProvider = permissions.find((permission) => permission.applicationOperation.name == 'Editar Proveedor') ? true : false;
    const validateRefuseProvider = permissions.find((permission) => permission.applicationOperation.name == 'Aprobar-Rechazar Proveedor') ? true : false;
    
    
    useEffect(() =>{
        getProviders();


    },[]);

    const getProviders = () =>{
        setLoadingBackDrop(true);
        let params = {
            filter: `{
                "include": [
                  {
                    "relation": "address",
                    "scope": {
                      "include": [{"relation": "provinceCatalog"},{"relation": "countryCatalog"}]
                    }
                  },
                  {"relation": "sociecityCatalog"},
                  {"relation": "fiscalRegulation"},
                  {"relation": "providerType"},
                  {"relation": "operationTypeCatalog"},
                  {"relation": "bankCountry"},
                  {"relation": "bankKeyCatalog"},
                  {"relation": "currencyCatalog"},
                  {"relation": "paymentConditionsCatalog"},
                  {"relation": "departmentCatalog"},
                  {"relation": "jobCatalog"}
                ]
              }`,

        };
       
        UserService.getProviders(params, (response,error) =>{
            setLoadingBackDrop(false);
            if(response && response.length){
                setProviders(response);
            }
        });
    }

    const openConfimrAlert = (flag) =>{
        setOpenConfirm(flag);
    }

    const openRefuseConfimrAlert = (flag) =>{
        setOpenRefuseConfirm(flag);
    }
    

    const validateProvider = () =>{
        openConfimrAlert(false);
        setLoadingBackDrop(true);
        let params = {
            id: selectedProvider.id,
            confirm: checked
        };
        UserService.validateProvider(params,(response,error) =>{
            setLoadingBackDrop(false);
            setSelectedProvider({});
            if(response && !error){
                let props = {open: true,
                    severity: response.success ? 'success' : 'error',
                    message: response.msg};
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                if(response.success)
                    getProviders();
            }
            else{
                let props = {open: true,
                    severity: 'error',
                    message: error.message}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }
        });   
    }

    const refuseProvider = () =>{
        openRefuseConfimrAlert(false);
        setLoadingBackDrop(true);
        let params = {
            id: selectedProvider.id
        };
        UserService.refuseProvider(params,(response,error) =>{
            setLoadingBackDrop(false);
            setSelectedProvider({});
            if(response && !error){
                let props = {open: true,
                    severity: response.success ? 'success' : 'error',
                    message: response.msg};
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                if(response.success)
                    getProviders();
            }
            else{
                let props = {open: true,
                    severity: 'error',
                    message: error.message}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }
        });   
    }

    const changeStatus = (event,cellValues) =>{
        setLoadingBackDrop(true);
        let params = {
            id: cellValues.row.id,
            body: {
                status: cellValues.row.status == 1 ? 0 : 1,
            }
        }
        UserService.updateProvider(params, (response,error) =>{
            setLoadingBackDrop(false);
            if(response && response.status == 204){
                let props = {open: true,
                    severity: 'success',
                    message: `Actualización exitosa`};
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                getProviders();
            }
            else{
                let props = {open: true,
                    severity: 'error',
                    message: error.message}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }
        });

    }

    const getClassName = (params) => {
        if (params.field === "status") {
          return params.value === "ACTIVO" ? "active-cell" : params.value == 'INACTIVO' ? "inactive-cell" : "revision-cell";
        }
        return "";
    }

    const getProviderDocuments = (providerId) =>{
        let params = {
            id: providerId,
            filter: '{}'
        }
        OperationServices.getProviderDocuments(params,(response,error) =>{
            setLoadingBackDrop(false);
            if(response && !error)
                setProviderDocuments(response);
        })
    }

    const downloadDocument = (dbDocument) =>{
        OperationServices.downloadProviderDocuments((dbDocument.id),(response) =>{
            var a = document.createElement('a');
            var url = window.URL.createObjectURL(response);
            a.href = url;
            a.download = dbDocument.fileName;
            document.body.append(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);

        })

        
    }

    const syncronizeProviders = () =>{
        setLoadingBackDrop(true);
        UserService.syncronizeProviders((response,error) =>{
            if(response && response.success){
                let props = {open: true,
                    severity: 'success',
                    message: `Sincronización exitosa`};
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                getProviders();
            }
            else{
                setLoadingBackDrop(false);
                let props = {open: true,
                    severity: 'error',
                    message: error.message}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }

        });
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'sapProviderId', headerName: 'SAP ID', width: 150 },
        { field: 'subjetc', headerName: 'Sociedad', width: 150,
            valueGetter: (params) =>{
                return params.row.sociecityCatalog?.name;
            },
        },
        { field: 'businessName', headerName: 'Razón social', width: 170},
        { field: 'rfc', headerName: 'RFC', width: 170},
        { field: 'mark', headerName: 'Marca', width: 170},
        { field: 'streetName', headerName: 'Calle', width: 150,
            valueGetter: (params) =>{
                return params.row.address.streetName;
            },
        },
        { field: 'outsideNumber', headerName: 'No. Exterior', width: 150,
            valueGetter: (params) =>{
                return params.row.address.outsideNumber;
            },
        },
        { field: 'insideNumber', headerName: 'No. Int.', width: 100,
            valueGetter: (params) =>{
                return params.row.address.insideNumber;
            },
        },
        { field: 'neighborhood', headerName: 'Colonia', width: 150,
            valueGetter: (params) =>{
                return params.row.address.neighborhood;
            },
        },
        { field: 'town', headerName: 'Población', width: 150,
            valueGetter: (params) =>{
                return params.row.address.town;
            },
        },
        { field: 'country', headerName: 'País', width: 150,
            valueGetter: (params) =>{
                return params.row.address.countryCatalog?.name;
            },
        },
        { field: 'province', headerName: 'Estado', width: 150,
            valueGetter: (params) =>{
                return params.row.address.provinceCatalog?.name;
            },
        },
        { field: 'status', headerName: 'Estatus', width: 170,
            valueGetter: (params) =>{
                let status = "";
                switch(params.row.status){
                    case 1:
                        status = "ACTIVO"
                        break;
                    case 2:
                        status = "EN REVISIÓN"
                        break;
                    case 0:
                        status = "INACTIVO"
                        break;
                }
                return status
            },
        },
        {
            field: '',
            headerName: 'Opciones',
            description: 'This column has a value getter and is not sortable.',
            width: 160,
            renderCell: (cellValues) => {
                return (
                    <React.Fragment>
                            <Tooltip title="Ver información">
                                <IconButton aria-label="edit" 
                                    onClick={(event) => {
                                        setLoadingBackDrop(true);
                                        setSelectedProvider(cellValues.row);
                                        setOpenDetails(true);
                                        getProviderDocuments(cellValues.row.id);
                                    }}
                                    >
                                        <Visibility />
                                </IconButton>
                            </Tooltip>
                        {
                            ((cellValues.row.status == 1 || cellValues.row.status == 0) && changeStatusProvider) ?
                            
                                cellValues.row.status == 0 ?
                                <Tooltip title="Habilitar">
                                <IconButton aria-label="Habilitar" 
                                    onClick={(event) => {
                                        changeStatus(event, cellValues);
                                    }}
                                    >
                                        < CheckBoxOutlineBlankOutlined/>
                                </IconButton>
                                </Tooltip>
                                :
                                <Tooltip title="Deshabilitar">
                                    <IconButton aria-label="Deshabilitar" 
                                        onClick={(event) => {
                                            changeStatus(event, cellValues);
                                        }}
                                        >
                                            < CheckBoxOutlined/>
                                    </IconButton>
                                </Tooltip>
                            :
                            ""

                        }
                        {
                            (cellValues.row.status == 2 && validateRefuseProvider) ?
                                <Fragment>
                                    <Tooltip title="Validar proveedor">
                                        <IconButton aria-label="edit" 
                                            onClick={(event) => {
                                                setChecked(true);
                                                openConfimrAlert(true);
                                                setSelectedProvider(cellValues.row);
                                                //showDetails(event,cellValues);
                                            }}
                                            >
                                                <FactCheckOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Rechazar proveedor">
                                        <IconButton aria-label="edit" 
                                            onClick={(event) => {
                                                openRefuseConfimrAlert(true);
                                                setSelectedProvider(cellValues.row);
                                                //showDetails(event,cellValues);
                                            }}
                                            >
                                                <CancelOutlined />
                                        </IconButton>
                                    </Tooltip>

                                </Fragment>
                            : ""

                        }
                        
                    </React.Fragment>
                );
            }
            
        },
    ];

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const checkConfirm = () =>{
        return (
            <FormControlLabel control={<Checkbox checked={checked}
            onChange={handleChange} />} label="Enviar confirmación" />
        )
    }


    return(
        <Fragment>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingBackDrop}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
            <FloatAlert/>
            <ConfirmAlert 
                open={openConfirm} 
                onclose={() =>{openConfimrAlert(false)}} 
                onclick={() => {validateProvider()}} 
                title={"Confirmación"}
                context={"Una vez validando la información del proveedor se le asignará un usuario y contraseña para el acceso al portal\n\n ¿Está seguro que desea realizar la operación?"}
                check={checkConfirm()}
            />
            <ConfirmAlert 
                open={openRefuseConfirm} 
                onclose={() =>{openRefuseConfimrAlert(false)}} 
                onclick={() => {refuseProvider()}} 
                title={"Rechazar proveedor"}
                context={"¿Está seguro que desea realizar la operación?"}
            />
            <MenuComponent permissionName={"Proveedores"}>
                <DataTable columns={columns} rows={providers} getClassName={getClassName}/>
                <Box sx={{flexGrow: 1}}></Box>
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                    <Tooltip title="Sincronizar proveedores">
                        <Fab color="primary" aria-label="add" onClick={() =>{syncronizeProviders()}}
                            sx={{position: 'fixed',bottom: 16, right: 16,}}>
                            <SyncOutlined />
                        </Fab>
                    </Tooltip>
                </Box>
            </MenuComponent>
            <Dialog
                open={openDetails}
                onClose={() =>{setOpenDetails(false)}}
                fullWidth
                maxWidth={"lg"}
            >
                <DialogTitle>Información Proveedor "{selectedProvider.businessName}"</DialogTitle>
                <DialogContent>
                    <Container component="main" maxWidth="lg">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} sx={{textAlign: 'center'}}>
                            <Typography variant="h6" gutterBottom>
                                <b>Datos del proveedor</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Sociedad: </b>{selectedProvider.sociecityCatalog?.name}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Razón social: </b>{selectedProvider.businessName}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>R.F.C: </b>{selectedProvider.rfc}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Marca: </b>{selectedProvider.mark}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Correo: </b>{selectedProvider.email}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Teléfono: </b>{selectedProvider.phone}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Móvil: </b>{selectedProvider.phoneMobile}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Régimen Fiscal: </b>{selectedProvider.fiscalRegulation?.code +' ' + selectedProvider.fiscalRegulation?.name}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Tipo Proveedor: </b>{selectedProvider.providerType?.code +' ' + selectedProvider.providerType?.name}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Tipo Operación: </b>{selectedProvider.operationTypeCatalog?.code +' ' + selectedProvider.operationTypeCatalog?.name}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>País Datos Banc.: </b>{selectedProvider.bankCountry?.acronym}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Clave Banco: </b>{selectedProvider.bankKeyCatalog?.code + ' ' + selectedProvider.bankKeyCatalog?.bank}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Clabe Interbancaria: </b>{selectedProvider.interbankClabe}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Moneda Factura: </b>{selectedProvider.currencyCatalog?.acronym}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Condiciones Pago: </b>{selectedProvider.paymentConditionsCatalog?.name}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            
                        </Grid>
                        <Grid item xs={12} sm={12} sx={{textAlign: 'center'}}>
                            <Typography variant="h6" gutterBottom>
                                <b>Datos Domiclio</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Calle: </b>{selectedProvider.address?.streetName}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>No. Exterior: </b>{selectedProvider.address?.outsideNumber}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>No. Interior: </b>{selectedProvider.address?.insideNumber}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Colonia: </b>{selectedProvider.address?.neighborhood}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Código Postal: </b>{selectedProvider.address?.postalCode}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Población: </b>{selectedProvider.address?.town}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>País: </b>{selectedProvider.address?.countryCatalog?.acronym}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Estado: </b>{selectedProvider.address?.provinceCatalog?.acronym + ' ' + selectedProvider.address?.provinceCatalog?.name}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} sx={{textAlign: 'center'}}>
                            <Typography variant="h6" gutterBottom>
                                <b>Datos Contacto</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Nombre Completo: </b>{selectedProvider.contactName}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Correo: </b>{selectedProvider.contactEmail}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Celular: </b>{selectedProvider.contactPhone}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Departamento: </b>{selectedProvider.departmentCatalog?.code + ' ' + selectedProvider.departmentCatalog?.name}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <p><b>Puesto: </b>{selectedProvider.jobCatalog?.code + ' ' + selectedProvider.jobCatalog?.name}</p>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                        </Grid>
                        {
                            providerDocuments.map((document) =>{
                                return(
                                    <Grid item xs={12} sm={3} md={3} key={"grd-"+document.id}>
                                        <Button startIcon={<DownloadOutlined/>} fullWidth variant="contained" onClick={() =>{downloadDocument(document)}} component="label" key={"btn"+document.id}>
                                            {document.fileName}
                                        </Button>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                        
                    </Container>
                </DialogContent>
                <DialogActions>
                <Button onClick={() =>{setOpenDetails(false)}}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )

}

export default ProviderIndex;