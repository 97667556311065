import React, { Fragment, useEffect, useState } from "react";
import MenuComponent from "../../Components/Menu/Menu";
import DataTable from "../../Components/DataTable/DataTable";
import { Autocomplete, Backdrop, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, TextField, Tooltip, Typography } from "@mui/material";
import { AddOutlined, CloseOutlined, FilterListOutlined, Save, Visibility } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from "react-redux";
import FloatAlert from "../../Components/Alert";
import { OperationServices } from "../../Services/OperationService";
import { OrderService } from "../../Services/OrderService";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dateFormatter from "../../Utils/DateFormatter";
import { UserService } from "../../Services/UserService";

const Orders = () =>{

    const [loadingBackDrop,setLoadingBackDrop] = useState(false);
    const [loading,setLoading] = useState(false);
    const [btnUploadXml,setBtnUploadXml] = useState("");
    const [btnUploadPdf,setBtnUploadPdf] = useState("");
    const [xmlFile,setXmlFile] = useState(null);
    const [pdfFile,setPdfFile] = useState(null);
    const [open,setOpen] = useState(false);
    const [openErrors,setOpenErrors] = useState(false);
    const [rowSelectionModel,setRowSelectionModel] = useState([]);
    const[orders,setOrders] = useState([]);
    const[errors,setErrors] = useState([]);
    const [filters, setShowFilters] = useState(false);
    const dispatch = useDispatch();
    const username = window.sessionStorage.getItem('username');
    const userId = window.sessionStorage.getItem('userId');
    const [filterType,setFilterType] = useState("C");
    const [filterDelivery,setFilterDelivery] = useState("C");
    const [appliedFilter, setAppliedFilter] = useState(false);
    
    const [providers,setProviders] = useState([]);
    const [providerValue,setProviderValue] = useState('');
    const [selectedProvider,setSelectedProvider] = useState({});

    let initialFilters = {
        from: '',
        to: '',
    }

    const[dateFilter,setDateFilter] = useState(initialFilters);

    let initialParams = {
        providerId: "",
        invoiced: filterType,
        delivered: filterDelivery,
        fromDate: '',
        toDate: ''
    }
    
    const {orderColumns} = useSelector(store => store.dataTableReducer);

    useEffect(() =>{
        //setShowFilters(true);
        //loadOrders(initialParams);
        getProviders();
    },[])


    const loadOrders = (params) =>{
        setLoadingBackDrop(true);
        OrderService.getOrders(params,(response,error) => {
            setLoadingBackDrop(false);
            if(response && response.success){
                setOrders(response.result);
            }
        })
    }

    const getProviders = () =>{
        setLoadingBackDrop(true);
        let params = {
            filter: `{
                "where":{"status": 1}
              }`,

        };
       
        UserService.getProviders(params, (response,error) =>{
            setLoadingBackDrop(false);
            setShowFilters(true);
            if(response && response.length){
                setProviders(response);
            }
        });
    }

    function filterOrders (){
        return(
            <Fragment>
                <Button onClick={() =>{
                    setShowFilters(true);
                }} startIcon={<FilterListOutlined />} >FILTROS</Button>
                {
                    appliedFilter ?
                    <Tooltip title="Limpiar Filtro">
                        <IconButton onClick={() =>{
                            setDateFilter(initialFilters);
                            //setLoadingBackDrop(true);
                            setAppliedFilter(false);
                            setOrders([]);
                            setProviderValue('');
                            setSelectedProvider({});
                            //loadOrders(initialParams);
                        }} aria-label="clear" size="small" color="primary">
                            <CloseOutlined />
                        </IconButton>
                    </Tooltip>
                    : ''
                }
                

            </Fragment>
        )
    }

    const columns = [
        // { field: 'id', headerName: 'ID', width: 100},
        { field: 'provider', headerName: 'Proveedor', width: 150},
        { field: 'sociecity', headerName: 'Sociedad', width: 150},
        { field: 'mandante', headerName: 'Mandante', width: 100},
        { field: 'orderNumber', headerName: 'No. Orden', width: 150},
        { field: 'position', headerName: 'Posición', width: 150},
        { field: 'creationDate', headerName: 'Fecha OC', width: 150},
        { field: 'orderType', headerName: 'Tipo OC', width: 150},
        { field: 'documentType', headerName: 'Tipo Documento', width: 150},
        { field: 'emisorRfc', headerName: 'RFC Emisor', width: 150},
        { field: 'receptorRfc', headerName: 'RFC Receptor', width: 150},
        { field: 'paymentCondSat', headerName: 'Condiciones P.', width: 100},
        { field: 'quantity', headerName: 'Cantidad', width: 150},
        { field: 'measureUnit', headerName: 'Unidad medida', width: 150},
        // { field: 'invoiceType', headerName: 'Tipo Doc', width: 150},
        { field: 'product', headerName: 'Código producto', width: 150},
        { field: 'description', headerName: 'Descripción', width: 150},
        { field: 'provMatCode', headerName: 'Código Prod.', width: 150},
        { field: 'unitPrice', headerName: 'P. Unitario', width: 150},
        { field: 'import', headerName: 'Importe', width: 100},
        { field: 'discount', headerName: 'Descuento', width: 100},
        { field: 'subtotal', headerName: 'Subtotal', width: 100},
        { field: 'tasa', headerName: 'Tasa', width: 100},
        { field: 'iva', headerName: 'IVA', width: 100},
        { field: 'total', headerName: 'Total', width: 100},
        { field: 'currency', headerName: 'Moneda', width: 150},
        // { field: 'documentType', headerName: 'Tipo Documento', width: 150},
        // { field: 'deliveryDate', headerName: 'Fecha Entrega', width: 150},
        { field: 'deliveryStatus', headerName: 'Estatus Entrega', width: 200},
        { field: 'invoiceStatus', headerName: 'Estatus Factura', width: 150},
        { field: 'receivedQuantity', headerName: 'Cantidad Entregada', width: 150},
        { field: 'invoicedQuantity', headerName: 'Cantidad Facturada', width: 150},
        { field: 'devQuantity', headerName: 'Cantidad Devolucion', width: 150},
        { field: 'matP', headerName: 'Kit', width: 50,
            valueGetter: (params) =>{
                return params.row.matP == 'X' ? "SI" : "";
            },
        },
        { field: 'matH', headerName: 'No. Kit', width: 200,
            valueGetter: (params) =>{
                return params.row.matP == 'X' ? params.row.matH : "";
            },
        },
         // {
        //     field: '',
        //     headerName: 'Opciones',
        //     description: 'This column has a value getter and is not sortable.',
        //     width: 160,
        //     renderCell: (cellValues) => {
        //         return (
        //             <React.Fragment>
        //                  <Tooltip title="Ver detalles">
        //                     <IconButton aria-label="edit" 
        //                         onClick={(event) => {
        //                             //showDetails(event,cellValues);
        //                         }}
        //                         >
        //                             <Visibility />
        //                     </IconButton>
        //                 </Tooltip>
                        
        //             </React.Fragment>
        //         );
        //     }
            
        // },
    ];

    const handleCaptureXml = ({ target }) => {
        setBtnUploadXml(target.files[0].name)
        setXmlFile(target.files[0]);
    };

    const handleCapturePdf = ({ target }) => {
        setBtnUploadPdf(target.files[0].name)
        setPdfFile(target.files[0]);
    };

    const handleClose = (e,reason) =>{
        if(!reason){
            setOpen(false);
            setBtnUploadXml("");
            setBtnUploadPdf("");
            setXmlFile(null);
            setPdfFile(null);
            setRowSelectionModel([]);
        }

    }
    const handleCloseErrors = (e,reason) =>{
        if(!reason){
            setOpenErrors(false);
            setErrors([]);
        }

    }

    const openUploadInvoice = () =>{
        if(rowSelectionModel.length){
            setOpen(true);
        }
        else{
            let props = {open: true,
                severity: 'warning',
                message: `Es necesario seleccionar al menos un registro`};
            dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
        }
    }

    const uploadInvoice = () =>{
        setLoading(true);
        let selectedOrders = [];
        for (const id of rowSelectionModel) {
            let order = orders.find((o) => o.id == id);
            if(order)
                selectedOrders.push(order);
        }
        const formData = new FormData();
        formData.append("file", xmlFile);    
        formData.append("file", pdfFile);    
        formData.append("orders",JSON.stringify(selectedOrders));
        formData.append("userId",userId);
        OrderService.uploadProviderInvoice(formData,username,(response,error) =>{
            setLoading(false);
            if(response && !error){
                if(response.success){
                    let props = {open: true,
                        severity: 'success',
                        message: `Factura cargada correctamente`};
                    dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                    handleClose();
                    loadOrders(getOrderParams());

                }
                else{
                    handleClose();
                    setErrors(response.errors);
                    setOpenErrors(true);
                }
            }
            else{
                let props = {open: true,
                    severity: 'error',
                    message: error.message}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }
        })

    }

    const handleCloseFilters = (e,reason) =>{
        if(!reason){
            // setDateFilter(initialFilters);
            setShowFilters(false);
        }
    }

    const applyFilter = () =>{
        setShowFilters(false);
        setAppliedFilter(true);
        
        loadOrders(getOrderParams());
    }

    const filterOptions = (options, state) => {
        const displayOptions = options.filter((option) =>
          option.businessName
            .toLowerCase()
            .trim()
            .includes(state.inputValue.toLowerCase().trim())
        );
        if(!displayOptions){
            displayOptions = options.filter((option) =>
            option.sapProviderId
            .toLowerCase()
            .trim()
            .includes(state.inputValue.toLowerCase().trim()),
            )
        }

        return displayOptions;
    }

    const getOrderParams = () =>{
        let from = dateFilter.from == '' ? "" : dateFormatter(dateFilter.from['$d'],'');
        let to = dateFilter.to == '' ? "" : dateFormatter(dateFilter.to['$d'],'');
        const params = {
            providerId: selectedProvider.sapProviderId ? selectedProvider.sapProviderId : "",
            invoiced: filterType,
            delivered: filterDelivery,
            fromDate: from,
            toDate: to
        }
        return params;
    }

    return(
        <Fragment>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingBackDrop}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
            <FloatAlert/>
            <MenuComponent permissionName={"Ordenes de compra"}>
                <DataTable columns={columns} rows={orders} 
                    checkboxSelection={true} disableRowSelectionOnClick={true} 
                    setSelectionModel={setRowSelectionModel}
                    selectionModel={rowSelectionModel}
                    filterDate={filterOrders}
                    columnVisibilityModel={orderColumns}
                    setColumnVisibilityModel={(newModel) =>{dispatch({type:'UPDATE_ORDER_COLUMS',idx:'orderColumns',value: newModel})}}
                    // onRowSelectionModelChange={(newRowSelectionModel) => {
                    //     setRowSelectionModel(newRowSelectionModel);
                    //   }}
                    // rowSelectionModel={rowSelectionModel}
                    />
                {/* <Box sx={{flexGrow: 1}}></Box>
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                    <Fab  color="primary" aria-label="add" onClick={() =>{openUploadInvoice()}}
                        sx={{position: 'fixed',bottom: 16, right: 16,}}>
                        <AddOutlined />
                    </Fab>
                </Box> */}
            </MenuComponent>
            <Dialog disableEscapeKeyDown={true} open={open} onClose={handleClose}>
                <DialogTitle>Subir Factura</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <TextField fullWidth variant="standard" required label="XML" value={btnUploadXml} /*onChange={(e) =>{setValue({...value,subject:e.target.value})}}*//>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Button fullWidth variant="contained" component="label">
                                {/* {btnUploadXml} */}
                                Cargar XML
                                <input hidden accept="application/xml" multiple type="file" onChange={handleCaptureXml} />
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField fullWidth variant="standard" required label="PDF" value={btnUploadPdf} /*onChange={(e) =>{setValue({...value,comments:e.target.value})}}*//>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Button fullWidth variant="contained" component="label">
                                Cargar PDF
                                {/* {btnUploadPdf} */}
                                <input hidden accept="application/pdf" multiple type="file" onChange={handleCapturePdf} />
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                <Button disabled={loading} startIcon={<CloseOutlined />} onClick={handleClose} size="small" variant="outlined">Cancelar</Button>
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<Save />}
                    variant="outlined"
                    loadingIndicator={<CircularProgress color="primary" size={16}/>}
                    size="small"
                    disabled={(!xmlFile || !pdfFile)}
                    onClick={() =>{uploadInvoice()}}
                >
                    Enviar
                </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog disableEscapeKeyDown={true} open={openErrors} onClose={handleCloseErrors}>
                <DialogTitle>Factura con errores</DialogTitle>
                <DialogContent>
                    {
                        errors.map((error,idx) =>{
                            return(
                                <Fragment>
                                    {/* <Chip key={idx} label={error} color="warning" />
                                    <br/>
                                    <br/> */}
                                    <Typography variant="body2" color={"red"}>* {error}</Typography>
                                    <Divider/>
                                    {/* <br></br>
                                    <br></br> */}
                                </Fragment>
                                
                            )
                        })
                    }
                </DialogContent>
                <DialogActions>
                    <Button startIcon={<CloseOutlined />} onClick={handleCloseErrors} size="small" variant="outlined">Cerrar</Button>
                </DialogActions>
            </Dialog>
            <Dialog disableEscapeKeyDown={true} open={filters} onClose={handleCloseFilters} fullWidth >
                <DialogTitle>Filtrar ordenes de compra</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                        <Autocomplete
                                    disablePortal
                                    options={providers}
                                    renderOption={(props, item) =>  {return (<li {...props} key={item.id}> {item.sapProviderId} {item.businessName}</li>)}}
                                    // filterOptions={filterOptions}
                                    getOptionLabel={(option) =>{return option?.businessName ? (`${option.sapProviderId} ${option.businessName}`) : ''}}
                                    value={selectedProvider}
                                    isOptionEqualToValue={(option, value) =>
                                        option.businessName === value?.businessName
                                    }
                                    onChange={(event, newValue,reason) => {
                                        if(newValue){
                                            setSelectedProvider(newValue)
                                            setProviderValue(newValue.businessName);
                                        }
                                    }}
                                    inputValue={providerValue}
                                    onInputChange={(event, newInputValue) => {
                                        setProviderValue(newInputValue);
                                    }}
                                    // // size="small"
                                    renderInput={(params) => <TextField required fullWidth
                                        variant="standard" {...params} label="Proveedor" />}
                                />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Estatus Factura</FormLabel>
                            <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={filterType}
                                    onChange={(e) =>{setFilterType(e.target.value)}}
                                >
                                    <FormControlLabel value="C" control={<Radio />} label="Facturado totalmente" />
                                    <FormControlLabel value="P" control={<Radio />} label="Parcial o No Facturado" />
                                    <FormControlLabel value="" control={<Radio />} label="Todos" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                        <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Estatus Entrega</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={filterDelivery}
                                    onChange={(e) =>{setFilterDelivery(e.target.value)}}
                                >
                                    <FormControlLabel value="C" control={<Radio />} label="Entregado por completo" />
                                    <FormControlLabel value="P" control={<Radio />} label="Entregado parcial" />
                                    <FormControlLabel value="N" control={<Radio />} label="No Entregado" />
                                    <FormControlLabel value="" control={<Radio />} label="Todos" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="F. Inicio"
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    value={dateFilter.from}
                                    onChange={(newValue) => {
                                        setDateFilter({...dateFilter,from: newValue});
                                    }}
                                    inputFormat="DD-MM-YYYY"
                                    renderInput={(params) => <TextField required size="small" {...params} />}
                                />
                                <Box sx={{flexGrow: 1}}></Box>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="F. Fin"
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    minDate={dateFilter.from}
                                    disabled={!dateFilter.from}
                                    value={dateFilter.to}
                                    onChange={(newValue) => {
                                        setDateFilter({...dateFilter,to: newValue});
                                    }}
                                    inputFormat="DD-MM-YYYY"
                                    renderInput={(params) => <TextField required size="small" {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                <Button startIcon={<CloseOutlined />} onClick={handleCloseFilters} size="small" variant="outlined">Cerrar</Button>
                <Button startIcon={<FilterListOutlined />} onClick={applyFilter} size="small" variant="outlined">Filtrar</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )

}

export default Orders;