import API_ROUTES from '../Routes/APIRoutes';


export const UserService = {
    
    login: (params, callback) => {
        fetch(API_ROUTES.login,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params) 
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
            })
            
        })
        .catch((err)=>callback(null, err));
    },
    recoverPass: (params, callback) => {
        fetch(API_ROUTES.recoverPass,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params) 
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
            })
            
        })
        .catch((err)=>callback(null, err));
    },
    addProvider: (params, callback) => {
        fetch(API_ROUTES.addProvider,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params) 
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
            })
            
        })
        .catch((err)=>callback(null, err));
    },
    getProviders: (params,callback) =>{
        let filter = params.filter;
        fetch(API_ROUTES.getProviders+`?filter=${encodeURI(filter)}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                callback(null,response.error);
                else
                    callback(response);
                })
                
            })
        .catch((err)=>callback(null, err));
    },
    validateProvider: (params, callback) => {
        fetch(API_ROUTES.validateProviders,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
            body: JSON.stringify(params) 
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
            })
            
        })
        .catch((err)=>callback(null, err));
    },
    refuseProvider: (params, callback) => {
        fetch(API_ROUTES.refuseProvider,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
            body: JSON.stringify(params) 
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
            })
            
        })
        .catch((err)=>callback(null, err));
    },
    getUserById: (params,callback) =>{
        const filter = params.filter ? `?filter=${params.filter}` : ""
        fetch(API_ROUTES.getUser + `/${params.userId}` + filter, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
                })
                
            })
        .catch((err)=>callback(null, err));
    },
    updatePassword: (params, callback) => {
        fetch(API_ROUTES.updatePassword,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
            body: JSON.stringify(params) 
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
            })
            
        })
        .catch((err)=>callback(null, err));
    },
    updateProvider: (params,callback) =>{
        fetch(API_ROUTES.updateProvider+`/${params.id}`, {
                method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
            body: JSON.stringify(params.body) // body data type must match "Content-Type" header
        })
        .then(res =>{
            if(res.status !== 204){
                res.json().then(response=>{
                    if(response.error)
                    callback(null,response.error);
                    else
                    callback(response);
                })
            }
            else{
                callback(res);
            } 
        }).catch((err)=> callback(null, err));
    },
    getRoles: (params,callback) =>{
        let filter = params.filter;
        fetch(API_ROUTES.getRoles+`?filter=${encodeURI(filter)}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                callback(null,response.error);
                else
                    callback(response);
                })
                
            })
        .catch((err)=>callback(null, err));
    },
    getApplicationOperations: (params,callback) =>{
        let filter = params.filter;
        fetch(API_ROUTES.getApplicationOperations+`?filter=${encodeURI(filter)}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                callback(null,response.error);
                else
                    callback(response);
                })
                
            })
        .catch((err)=>callback(null, err));
    },
    getApplicationAuthorizations: (params,callback) =>{
        let filter = params.filter;
        fetch(API_ROUTES.getApplicationAuthorizations+`?filter=${encodeURI(filter)}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                callback(null,response.error);
                else
                    callback(response);
                })
                
            })
        .catch((err)=>callback(null, err));
    },
    addApplicationAuthorizationToRole: (params, callback) => {
        fetch(API_ROUTES.addApplicationAuthorizationToRole,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
            body: JSON.stringify(params) 
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
            })
            
        })
        .catch((err)=>callback(null, err));
    },
    getUsers: (params,callback) =>{
        let filter = params.filter;
        fetch(API_ROUTES.getUser+`?filter=${encodeURI(filter)}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                callback(null,response.error);
                else
                    callback(response);
                })
                
            })
        .catch((err)=>callback(null, err));
    },
    addUser: (params, callback) => {
        fetch(API_ROUTES.addUser,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
            body: JSON.stringify(params) 
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
            })
            
        })
        .catch((err)=>callback(null, err));
    },
    updateUser: (params,callback) =>{
        fetch(API_ROUTES.addUser+`/${params.id}`, {
                method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
            body: JSON.stringify(params.body) // body data type must match "Content-Type" header
        })
        .then(res =>{
            if(res.status !== 204){
                res.json().then(response=>{
                    if(response.error)
                    callback(null,response.error);
                    else
                    callback(response);
                })
            }
            else{
                callback(res);
            } 
        }).catch((err)=> callback(null, err));
    },
    changeUserStatus: (params,callback) =>{
        fetch(API_ROUTES.changeUserStatus+`/${params.id}`, {
            method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
            body: JSON.stringify(params.body) // body data type must match "Content-Type" header
        })
        .then(res =>{
            if(res.status !== 204){
                res.json().then(response=>{
                    if(response.error)
                    callback(null,response.error);
                    else
                    callback(response);
                })
            }
            else{
                callback(res);
            } 
        }).catch((err)=> callback(null, err));
    },
    syncronizeProviders: (callback) => {
        fetch(API_ROUTES.syncronizeProviders,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            } 
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
            })
            
        })
        .catch((err)=>callback(null, err));
    },
    validateToken: (callback) => {
        fetch(API_ROUTES.validateToken,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
            })
            
        })
        .catch((err)=>callback(null, err));
    },

    
}