const initialState = {
    username: '',
    password: '',
    id: 0,
    permissions: [],
    roleId: 0,
    name: '',
    changePassword: false
}

const userReducer = (state = initialState, action) => {
    switch(action.type){
        case 'UPDATE_USER_DATA':
            state = {...state};
            state[action.idx] = action.value;
            return state;
        case 'SET_PERMISSIONS':
            state = {...state};
            state[action.idx] = action.value;
            return state;
        default:
            return state;
    }
}

export default userReducer;