import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const FloatAlert = () =>{
    const dispatch = useDispatch();
    let {alert} = useSelector(store => store.componentReducer);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch({type:'RESET_ALERT',idx:'alert'})
        //setAlertValue({...alertValue,open: false});
    };

    return(
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={alert.props?.open} autoHideDuration={4000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={alert.props?.severity} sx={{ width: '100%' }}>
            {alert.props?.message}
            </Alert>
        </Snackbar>
    )

}

export default FloatAlert;