import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { OperationServices } from "../../Services/OperationService";
import { AppBar, Autocomplete, Backdrop, Button, Checkbox, CircularProgress, Container, FormControlLabel, FormHelperText, Grid, TextField, Toolbar, Typography, styled } from "@mui/material";
import { CatalogService } from "../../Services/CatalogService";
import API_ROUTES from "../../Routes/APIRoutes";
import { LoadingButton } from "@mui/lab";
import { CheckCircleRounded, DownloadOutlined, Save } from "@mui/icons-material";
import FloatAlert from "../../Components/Alert";
import { useDispatch } from "react-redux";
import { UserService } from "../../Services/UserService";


const ProviderRegister = () =>{

    const initialValue = {
        businessName: '',
        mark: '',
        address:{},
        phone: '',
        phoneMobile: '',
        email: '',
        rfc: '',
        interbankClabe: '',
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        streetName: '',
        outsideNumber: '',
        insideNumber: '',
        neighborhood: '',
        postalCode: '',
        town: '',
        tokenId: 0,


    }

    

    const { token } = useParams();
    const [response,setResponse] = useState({});
    const [value,setValue] = useState(initialValue);
    const [loading,setLoading] = useState(false);
    const [loadingBackDrop,setLoadingBackDrop] = useState(false);

    const [sociecities,setSociecities] = useState([]);
    const [sociecityValue,setSociecityValue] = useState('');
    const [selectedSociecity,setSelectedSociecity] = useState({});

    const [fiscalRegulations,setFiscalRegulations] = useState([]);
    const [fiscalRegulationValue,setFiscalRegulationValue] = useState('');
    const [selectedFiscalRegulation,setSelectedFiscalRegulation] = useState({});

    const [providerTypes,setProviderTypes] = useState([]);
    const [providerTypeValue,setProviderTypeValue] = useState('');
    const [selectedProviderType,setSelectedProviderType] = useState({});

    const [operationTypes,setOperationTypes] = useState([]);
    const [operationTypeValue,setOperationTypeValue] = useState('');
    const [selectedOperationType,setSelectedOperationType] = useState({});

    const [bankKeys,setBankKeys] = useState([]);
    const [bankKeyValue,setBankKeyValue] = useState('');
    const [selectedBankKey,setSelectedBankKey] = useState({});
    
    const [countries,setCountries] = useState([]);
    const [countryValue,setCountryValue] = useState('');
    const [selectedCountry,setSelectedCountry] = useState({});

    const [provinces,setProvinces] = useState([]);
    const [provinceValue,setProvinceValue] = useState('');
    const [selectedProvince,setSelectedProvince] = useState({});

    const [bankCountryValue,setBanKCountryValue] = useState('');
    const [selectedBankCountry,setSelectedBankCountry] = useState({});

    const [currencies,setCurrencies] = useState([]);
    const [currencyValue,setCurrencyValue] = useState('');
    const [selectedCurrency,setSelectedCurrency] = useState({});

    const [paymentConditions,setPaymentConditions] = useState([]);
    const [paymentConditionValue,setPaymentConditionValue] = useState('');
    const [selectedPaymentCondition,setSelectedPaymentCondition] = useState({});

    const [departments,setDepartments] = useState([]);
    const [departmentValue,setDepartmentValue] = useState('');
    const [selectedDepartment,setSelectedDepartment] = useState({});

    const [jobs,setJobs] = useState([]);
    const [jobValue,setJobValue] = useState('');
    const [selectedJob,setSelectedJob] = useState({});

    const [saveAttemp,setSaveAttemp] = useState(false);
    const requiredFields = ['businessName','mark','phone','phoneMobile','email','rfc','interbankClabe','streetName','outsideNumber','neighborhood','town','contactName','contactEmail','contactPhone']
    const requiredSelects = [sociecityValue,fiscalRegulationValue,providerTypeValue,operationTypeValue,bankCountryValue,bankKeyValue,currencyValue,paymentConditionValue,countryValue,provinceValue,departmentValue,jobValue];
    
    const [CSFFile,setCSFFile] = useState(null);
    const [INEFile,setINEFile] = useState(null);
    const [edoCtaFile,setEdoCtaFile] = useState(null);
    const [CDFile,setCDFile] = useState(null);
    const [OCFile,setOCFile] = useState(null);
    const [ACFile,setACFile] = useState(null);
    const [PFile,setPFile] = useState(null);
    const [LPFile,setLPFile] = useState(null);
    const [PGFile,setPGFile] = useState(null);
    const [acceptTerms,setAcceptTerms] = useState(false);
    const requiredFiles = [CSFFile,INEFile,edoCtaFile,CDFile,OCFile];
    const dispatch = useDispatch();

    const [registerSuccess,setRegisterSuccess] = useState(false);

    // const regex = /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/;

    const regex = /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/;

    useLayoutEffect(() =>{
        setLoadingBackDrop(true);
        OperationServices.validateRegisterToken({token: token}, (response, error) =>{
            setLoadingBackDrop(false);
            if(response){
                setResponse(response);
                // setResponse({success:true});
                setValue({...value,tokenId: response.result.id, email: response.result.email,businessName: response.result.businessName,rfc: response.result.rfc});
            }
        });

    },[])

    useEffect(() =>{
        getCatalog(API_ROUTES.getSociecities,setSociecities);
        getCatalog(API_ROUTES.getFiscalRegulations,setFiscalRegulations);
        getCatalog(API_ROUTES.getOperationTypes,setOperationTypes);
        getCatalog(API_ROUTES.getProviderTypes,setProviderTypes);
        getCatalog(API_ROUTES.getBankKeys,setBankKeys);
        getCatalog(API_ROUTES.getCountries,setCountries);
        getCatalog(API_ROUTES.getProvinces,setProvinces);
        getCatalog(API_ROUTES.getCurrencies,setCurrencies);
        getCatalog(API_ROUTES.getPaymentConditions,setPaymentConditions);
        getCatalog(API_ROUTES.getDepartments,setDepartments);
        getCatalog(API_ROUTES.getJobs,setJobs);
    },[]);

    const getCatalog = (route,setArray) =>{
        let params = {filter: `{"where": {"status" : 1}}`, apiRoute: route};
        CatalogService.getCatalog(params,(response,error) =>{
            if(response?.length){
                setArray(response);
            }
        })
    }

    const register = (e) =>{
        e.preventDefault();
        setSaveAttemp(true);
        if(validateFields()){
            if(acceptTerms){

                setLoading(true);
                setLoadingBackDrop(true);
                let newProvider = {
                    businessName: value.businessName,
                    mark: value.mark,
                    address:{
                        streetName: value.streetName,
                        outsideNumber: value.outsideNumber,
                        insideNumber: value.insideNumber,
                        neighborhood: value.neighborhood,
                        postalCode: value.postalCode,
                        town: value.town,
                        provinceCatalogId: selectedProvince.id,
                        countryCatalogId: selectedCountry.id,
                    },
                    phone: value.phone,
                    phoneMobile: value.phoneMobile,
                    email: value.email,
                    rfc: value.rfc,
                    interbankClabe: value.interbankClabe,
                    contactName: value.contactName,
                    contactPhone: value.contactPhone,
                    contactEmail: value.contactEmail,
                    tokenId: value.tokenId,
                    sociecityId: selectedSociecity.id,
                    fiscalRegulationId: selectedFiscalRegulation.id,
                    providerTypeId: selectedProviderType.id,
                    operationTypeId: selectedOperationType.id,
                    bankCountryId: selectedBankCountry.id,
                    bankKeyId: selectedBankKey.id,
                    currencyId: selectedCurrency.id,
                    paymentConditionId: selectedPaymentCondition.id,
                    departmentId: selectedDepartment.id,
                    jobId: selectedJob.id
                    
                }
                UserService.addProvider(newProvider, (response,error) =>{
                    if(response && !error){
                        const formData = new FormData();
                        formData.append("file", CSFFile);    
                        formData.append("file", edoCtaFile);    
                        formData.append("file", INEFile);    
                        formData.append("file", CDFile);    
                        formData.append("file", OCFile);    
                        if(value.rfc.length == 12){
                            formData.append("file", PFile);    
                            formData.append("file", ACFile);    
                        }
                        if(LPFile != null)
                            formData.append("file", LPFile);    
                        if(PGFile != null)
                            formData.append("file", PGFile);    

                        
                        formData.append("providerId", response.result.id);
                        OperationServices.uploadProviderDocuments(formData,value.rfc,(response,error) =>{
                            setLoading(false);
                            setLoadingBackDrop(false);
                            if(response && !error){
                                let props = {open: true,
                                    severity: 'success',
                                    message: `Registro Exitoso`};
                                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                                setRegisterSuccess(true);
                            }
                            else{
                                let props = {open: true,
                                    severity: 'error',
                                    message: error.message}
                                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                            }
    
                        })
                        
                    }
                    else{
                        let props = {open: true,
                            severity: 'error',
                            message: error.message}
                        dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                    }
                });
            }
            else{
                let props = {open: true,
                    severity: 'warning',
                    message: 'Es necesario aceptar el aviso de privacidad'}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }
                
        }
        else{
            let props = {open: true,
                severity: 'warning',
                message: `Es necesario llenar los campos requeridos.`};
            dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
        }
    }

    const validateFields = () =>{
        for (const field of requiredFields) {
            if(!value[field].length){
                return false;
                break;
            }
        }

        for (const select of requiredSelects) {
            if(!select.length){
                return false;
                break;
            }
            
        }

        for (const file of requiredFiles) {
            if(!file){
                return false;
                break;
            }
            
        }
        return true;
    }


    const handleCapture = ({ target },flag) => {
        let size = (target.files[0].size / 1000000)
        if(size <= 20){
            if(flag == 1){
                setCSFFile(target.files[0]);
            }
            else if(flag == 2){
                setEdoCtaFile(target.files[0]);
            }
            else if(flag == 3){
                setINEFile(target.files[0]);
    
            }
            else if(flag == 4){
                setCDFile(target.files[0]);
            }
            else if(flag == 5){
                setOCFile(target.files[0]);
            }
            else if(flag == 6){
                setACFile(target.files[0]);
            }
            else if(flag == 7){
                setPFile(target.files[0]);
            }
            else if(flag == 8){
                setLPFile(target.files[0]);
            }
            else if(flag == 9){
                setPGFile(target.files[0]);
            }
        }
        else{
            let props = {open: true,
                severity: 'warning',
                message: `El tamaño del archivo excede los 20 MB`};
            dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
        }
        
        
    };

    const interbankClabeChange = (e) => {
        let onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setValue({...value,interbankClabe: onlyNums});
    }

    const postalCodeChange = (e) => {
        let onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setValue({...value,postalCode: onlyNums});
    }


    const downloadPrivacityFile = () =>{
        OperationServices.downloadPrivacityFile((response) =>{
            var a = document.createElement('a');
            var url = window.URL.createObjectURL(response);
            a.href = url;
            a.download = 'AvisoPrivacidad.docx';
            document.body.append(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);

        })

        
    }

    return(
        <Fragment>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingBackDrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <FloatAlert/>
            <AppBar position="relative">
                <Toolbar>
                {/* <CameraIcon sx={{ mr: 2 }} /> */}
                <Typography variant="h6" color="inherit" noWrap>
                    Registro portal de proveedores
                </Typography>
                </Toolbar>
            </AppBar>
            {
                response.success ? 
                    <Fragment>
                        
                        <Container component="main" maxWidth="lg" sx={{ mb: 4, mt: 4 }}>
                            {
                                !registerSuccess ? 
                                <Fragment>
                                    <Typography variant="h6" gutterBottom>
                                        Datos del proveedor
                                        </Typography>
                                        {/* <br/> */}
                                        <hr/>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={3}>
                                            <Autocomplete
                                                disablePortal
                                                options={sociecities}
                                                getOptionLabel={(option) =>{return option?.name ? (option.code + ' ' +option.name) : ''}}
                                                value={selectedSociecity}
                                                isOptionEqualToValue={(option, value) =>
                                                    option.name === value?.name
                                                }
                                                onChange={(event, newValue,reason) => {
                                                    if(newValue){
                                                        setSelectedSociecity(newValue)
                                                        setSociecityValue(newValue.name);
                                                    }
                                                }}
                                                inputValue={sociecityValue}
                                                onInputChange={(event, newInputValue) => {
                                                    setSociecityValue(newInputValue);
                                                }}
                                                // size="small"
                                                renderInput={(params) => <TextField required fullWidth
                                                    helperText={saveAttemp && !sociecityValue.length ? "Es requerido" : ""}
                                                    error={saveAttemp && !sociecityValue.length}
                                                    variant="standard" {...params} label="Sociedad" />}
                                            />
                                            
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    helperText={saveAttemp && !value.businessName.length ? "Es requerido" : ""}
                                                    error={saveAttemp && !value.businessName.length}
                                                    required
                                                    label="Razón Social"
                                                    fullWidth
                                                    inputProps={{ maxLength: 160}}
                                                    value={value.businessName}
                                                    onChange={(e) =>{setValue({...value,businessName: e.target.value})}}
                                                    variant="standard"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    required
                                                    label="R.F.C"
                                                    fullWidth
                                                    inputProps={{ minLength: 11, maxLength: 13, readOnly: true }}
                                                    value={value.rfc}
                                                    onChange={(e) =>{setValue({...value,rfc: e.target.value})}}
                                                    variant="standard"
                                                    helperText={saveAttemp && !value.rfc.length ? "Es requerido" : ""}
                                                    error={saveAttemp && !value.rfc.length}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    required
                                                    label="Marca"
                                                    fullWidth
                                                    inputProps={{ maxLength: 20}}
                                                    value={value.mark}
                                                    onChange={(e) =>{setValue({...value,mark: e.target.value})}}
                                                    variant="standard"
                                                    helperText={saveAttemp && !value.mark.length ? "Es requerido" : ""}
                                                    error={saveAttemp && !value.mark.length}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    required
                                                    label="Correo"
                                                    fullWidth
                                                    inputProps={{readOnly: true}}
                                                    value={value.email}
                                                    onChange={(e) =>{setValue({...value,email: e.target.value})}}
                                                    variant="standard"
                                                    helperText={saveAttemp && !value.email.length ? "Es requerido" : ""}
                                                    error={saveAttemp && !value.email.length}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    required
                                                    label="Teléfono"
                                                    fullWidth
                                                    inputProps={{ minLength: 10, maxLength: 10 }}
                                                    value={value.phone}
                                                    onChange={(e) =>{setValue({...value,phone: e.target.value})}}
                                                    variant="standard"
                                                    helperText={saveAttemp && !value.phone.length ? "Es requerido" : ""}
                                                    error={saveAttemp && !value.phone.length}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    required
                                                    label="Móvil"
                                                    fullWidth
                                                    inputProps={{ minLength: 10, maxLength: 10 }}
                                                    value={value.phoneMobile}
                                                    onChange={(e) =>{setValue({...value,phoneMobile: e.target.value})}}
                                                    variant="standard"
                                                    helperText={saveAttemp && !value.phoneMobile.length ? "Es requerido" : ""}
                                                    error={saveAttemp && !value.phoneMobile.length}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Autocomplete
                                                    disablePortal
                                                    options={fiscalRegulations}
                                                    getOptionLabel={(option) =>{return option?.name ? (option.code + ' ' +option.name) : ''}}
                                                    value={selectedFiscalRegulation}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.name === value?.name
                                                    }
                                                    onChange={(event, newValue,reason) => {
                                                        if(newValue){
                                                            setSelectedFiscalRegulation(newValue)
                                                            setFiscalRegulationValue(newValue.name);
                                                        }
                                                    }}
                                                    inputValue={fiscalRegulationValue}
                                                    onInputChange={(event, newInputValue) => {
                                                        setFiscalRegulationValue(newInputValue);
                                                    }}
                                                    // size="small"
                                                    renderInput={(params) => <TextField required fullWidth
                                                        variant="standard" {...params} label="Régimen Fiscal" 
                                                        helperText={saveAttemp && !fiscalRegulationValue.length ? "Es requerido" : ""}
                                                        error={saveAttemp && !fiscalRegulationValue.length}/>}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Autocomplete
                                                    disablePortal
                                                    options={providerTypes}
                                                    getOptionLabel={(option) =>{return option?.name ? (option.code + ' ' +option.name) : ''}}
                                                    value={selectedProviderType}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.name === value?.name
                                                    }
                                                    onChange={(event, newValue,reason) => {
                                                        if(newValue){
                                                            setSelectedProviderType(newValue)
                                                            setProviderTypeValue(newValue.name);
                                                        }
                                                    }}
                                                    inputValue={providerTypeValue}
                                                    onInputChange={(event, newInputValue) => {
                                                        setProviderTypeValue(newInputValue);
                                                    }}
                                                    // size="small"
                                                    renderInput={(params) => <TextField required fullWidth
                                                        variant="standard" {...params} label="Tipo Proveedor" 
                                                        helperText={saveAttemp && !providerTypeValue.length ? "Es requerido" : ""}
                                                    error={saveAttemp && !providerTypeValue.length}/>}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Autocomplete
                                                    disablePortal
                                                    options={operationTypes}
                                                    getOptionLabel={(option) =>{return option?.name ? (option.code + ' ' +option.name) : ''}}
                                                    value={selectedOperationType}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.name === value?.name
                                                    }
                                                    onChange={(event, newValue,reason) => {
                                                        if(newValue){
                                                            setSelectedOperationType(newValue)
                                                            setOperationTypeValue(newValue.name);
                                                        }
                                                    }}
                                                    inputValue={operationTypeValue}
                                                    onInputChange={(event, newInputValue) => {
                                                        setOperationTypeValue(newInputValue);
                                                    }}
                                                    // size="small"
                                                    renderInput={(params) => <TextField required fullWidth
                                                        variant="standard" {...params} label="Tipo Operación" 
                                                        helperText={saveAttemp && !operationTypeValue.length ? "Es requerido" : ""}
                                                        error={saveAttemp && !operationTypeValue.length}/>}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Autocomplete
                                                    disablePortal
                                                    options={countries}
                                                    getOptionLabel={(option) =>{return option?.name ? (option.acronym + ' ' +option.name) : ''}}
                                                    value={selectedBankCountry}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.name === value?.name
                                                    }
                                                    onChange={(event, newValue,reason) => {
                                                        if(newValue){
                                                            setSelectedBankCountry(newValue)
                                                            setBanKCountryValue(newValue.name);
                                                        }
                                                    }}
                                                    inputValue={bankCountryValue}
                                                    onInputChange={(event, newInputValue) => {
                                                        setBanKCountryValue(newInputValue);
                                                    }}
                                                    // size="small"
                                                    renderInput={(params) => <TextField required fullWidth
                                                        variant="standard" {...params} label="País Datos Bancarios" 
                                                        helperText={saveAttemp && !bankCountryValue.length ? "Es requerido" : ""}
                                                    error={saveAttemp && !bankCountryValue.length}/>}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Autocomplete
                                                    disablePortal
                                                    options={bankKeys}
                                                    getOptionLabel={(option) =>{return option?.bank ? (option.code + ' ' +option.bank) : ''}}
                                                    value={selectedBankKey}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.bank === value?.bank
                                                    }
                                                    onChange={(event, newValue,reason) => {
                                                        if(newValue){
                                                            setSelectedBankKey(newValue)
                                                            setBankKeyValue(newValue.bank);
                                                        }
                                                    }}
                                                    inputValue={bankKeyValue}
                                                    onInputChange={(event, newInputValue) => {
                                                        setBankKeyValue(newInputValue);
                                                    }}
                                                    // size="small"
                                                    renderInput={(params) => <TextField required fullWidth
                                                        variant="standard" {...params} label="Clave Banco" 
                                                        helperText={saveAttemp && !bankKeyValue.length ? "Es requerido" : ""}
                                                    error={saveAttemp && !bankKeyValue.length}/>}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    required
                                                    label="Clabe Interbancaria"
                                                    fullWidth
                                                    inputProps={{ minLength: 18, maxLength: 18 }}
                                                    value={value.interbankClabe}
                                                    onChange={(e) =>{interbankClabeChange(e)}}
                                                    variant="standard"
                                                    helperText={saveAttemp ? (!value.interbankClabe.length ? "Es requerido" : (value.interbankClabe.length < 18 ? "Clabe interbancaria debe ser de 18 dígitos" : "")) : ""}
                                                    error={saveAttemp && (value.interbankClabe.length < 18)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Autocomplete
                                                    disablePortal
                                                    options={currencies}
                                                    getOptionLabel={(option) =>{return option?.name ? (option.acronym + ' ' +option.name) : ''}}
                                                    value={selectedCurrency}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.name === value?.name
                                                    }
                                                    onChange={(event, newValue,reason) => {
                                                        if(newValue){
                                                            setSelectedCurrency(newValue)
                                                            setCurrencyValue(newValue.name);
                                                        }
                                                    }}
                                                    inputValue={currencyValue}
                                                    onInputChange={(event, newInputValue) => {
                                                        setCurrencyValue(newInputValue);
                                                    }}
                                                    // size="small"
                                                    renderInput={(params) => <TextField required fullWidth
                                                        variant="standard" {...params} label="Moneda Factura" 
                                                        helperText={saveAttemp && !currencyValue.length ? "Es requerido" : ""}
                                                        error={saveAttemp && !currencyValue.length}
                                                    />}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Autocomplete
                                                    disablePortal
                                                    options={paymentConditions}
                                                    getOptionLabel={(option) =>{return option?.name ? (option.code + ' ' +option.name) : ''}}
                                                    value={selectedPaymentCondition}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.name === value?.name
                                                    }
                                                    onChange={(event, newValue,reason) => {
                                                        if(newValue){
                                                            setSelectedPaymentCondition(newValue)
                                                            setPaymentConditionValue(newValue.name);
                                                        }
                                                    }}
                                                    inputValue={paymentConditionValue}
                                                    onInputChange={(event, newInputValue) => {
                                                        setPaymentConditionValue(newInputValue);
                                                    }}
                                                    // size="small"
                                                    renderInput={(params) => <TextField required fullWidth
                                                        variant="standard" {...params} label="Condiciones Pago" 
                                                        helperText={saveAttemp && !paymentConditionValue.length ? "Es requerido" : ""}
                                                        error={saveAttemp && !paymentConditionValue.length}/>}
                                                />
                                            </Grid>
                                        </Grid>
                                        <br/>
                                        <hr/>
                                        <Typography variant="h6" gutterBottom>
                                            Datos Domicilio
                                        </Typography>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    required
                                                    label="Calle"
                                                    fullWidth
                                                    inputProps={{ maxLength: 60}}
                                                    value={value.streetName}
                                                    onChange={(e) =>{setValue({...value,streetName: e.target.value})}}
                                                    variant="standard"
                                                    helperText={saveAttemp && !value.streetName.length ? "Es requerido" : ""}
                                                    error={saveAttemp && !value.streetName.length}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    required
                                                    label="Número Exterior"
                                                    fullWidth
                                                    inputProps={{ maxLength: 10}}
                                                    value={value.outsideNumber}
                                                    onChange={(e) =>{setValue({...value,outsideNumber: e.target.value})}}
                                                    variant="standard"
                                                    helperText={saveAttemp && !value.outsideNumber.length ? "Es requerido" : ""}
                                                    error={saveAttemp && !value.outsideNumber.length}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    label="Número Interior"
                                                    fullWidth
                                                    inputProps={{ maxLength: 10}}
                                                    value={value.insideNumber}
                                                    onChange={(e) =>{setValue({...value,insideNumber: e.target.value})}}
                                                    variant="standard"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    required
                                                    label="Colonia"
                                                    fullWidth
                                                    inputProps={{ maxLength: 40}}
                                                    value={value.neighborhood}
                                                    onChange={(e) =>{setValue({...value,neighborhood: e.target.value})}}
                                                    variant="standard"
                                                    helperText={saveAttemp && !value.neighborhood.length ? "Es requerido" : ""}
                                                    error={saveAttemp && !value.neighborhood.length}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    required
                                                    label="Código Postal"
                                                    fullWidth
                                                    value={value.postalCode}
                                                    onChange={(e) =>{postalCodeChange(e)}}
                                                    variant="standard"
                                                    inputProps={{ minLength: 5, maxLength: 5 }}
                                                    helperText={saveAttemp ? (!value.postalCode.length ? "Es requerido" : (value.postalCode.length < 5 ? "El código postal debe ser de 5 dígitos" : "")) : ""}
                                                    error={saveAttemp && (value.postalCode.length < 5)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    required
                                                    label="Población"
                                                    fullWidth
                                                    inputProps={{ maxLength: 40}}
                                                    value={value.town}
                                                    onChange={(e) =>{setValue({...value,town: e.target.value})}}
                                                    variant="standard"
                                                    helperText={saveAttemp && !value.town.length ? "Es requerido" : ""}
                                                    error={saveAttemp && !value.town.length}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Autocomplete
                                                    disablePortal
                                                    options={countries}
                                                    getOptionLabel={(option) =>{return option?.name ? (option.acronym + ' ' +option.name) : ''}}
                                                    value={selectedCountry}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.name === value?.name
                                                    }
                                                    onChange={(event, newValue,reason) => {
                                                        if(newValue){
                                                            setSelectedCountry(newValue)
                                                            setCountryValue(newValue.name);
                                                        }
                                                    }}
                                                    inputValue={countryValue}
                                                    onInputChange={(event, newInputValue) => {
                                                        setCountryValue(newInputValue);
                                                    }}
                                                    // size="small"
                                                    renderInput={(params) => <TextField required fullWidth
                                                        variant="standard" {...params} label="País" 
                                                        helperText={saveAttemp && !countryValue.length ? "Es requerido" : ""}
                                                        error={saveAttemp && !countryValue.length}
                                                    />}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Autocomplete
                                                    disablePortal
                                                    options={provinces}
                                                    getOptionLabel={(option) =>{return option?.name ? (option.acronym + ' ' +option.name) : ''}}
                                                    value={selectedProvince}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.name === value?.name
                                                    }
                                                    onChange={(event, newValue,reason) => {
                                                        if(newValue){
                                                            setSelectedProvince(newValue)
                                                            setProvinceValue(newValue.name);
                                                        }
                                                    }}
                                                    inputValue={provinceValue}
                                                    onInputChange={(event, newInputValue) => {
                                                        setProvinceValue(newInputValue);
                                                    }}
                                                    // size="small"
                                                    renderInput={(params) => <TextField required fullWidth
                                                        variant="standard" {...params} label="Estado" 
                                                        helperText={saveAttemp && !provinceValue.length ? "Es requerido" : ""}
                                                        error={saveAttemp && !provinceValue.length}
                                                    />}
                                                />
                                            </Grid>
                                        </Grid>
                                        <br/>
                                        <hr/>
                                        <Typography variant="h6" gutterBottom>
                                            Datos Contacto
                                        </Typography>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    required
                                                    label="Nombre Completo"
                                                    fullWidth
                                                    inputProps={{ maxLength: 35}}
                                                    value={value.contactName}
                                                    onChange={(e) =>{setValue({...value,contactName: e.target.value})}}
                                                    variant="standard"
                                                    helperText={saveAttemp && !value.contactName.length ? "Es requerido" : ""}
                                                    error={saveAttemp && !value.contactName.length}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    required
                                                    label="Correo"
                                                    fullWidth
                                                    inputProps={{ maxLength: 200}}
                                                    value={value.contactEmail}
                                                    onChange={(e) =>{setValue({...value,contactEmail: e.target.value})}}
                                                    variant="standard"
                                                    helperText={saveAttemp && !value.contactEmail.length ? "Es requerido" : ""}
                                                    error={saveAttemp && !value.contactEmail.length}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    required
                                                    label="Celular"
                                                    fullWidth
                                                    inputProps={{ minLength: 10, maxLength: 10 }}
                                                    value={value.contactPhone}
                                                    onChange={(e) =>{setValue({...value,contactPhone: e.target.value})}}
                                                    variant="standard"
                                                    helperText={saveAttemp && !value.contactPhone.length ? "Es requerido" : ""}
                                                    error={saveAttemp && !value.contactPhone.length}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Autocomplete
                                                    disablePortal
                                                    options={departments}
                                                    getOptionLabel={(option) =>{return option?.name ? (option.code + ' ' +option.name) : ''}}
                                                    value={selectedDepartment}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.name === value?.name
                                                    }
                                                    onChange={(event, newValue,reason) => {
                                                        if(newValue){
                                                            setSelectedDepartment(newValue)
                                                            setDepartmentValue(newValue.name);
                                                        }
                                                    }}
                                                    inputValue={departmentValue}
                                                    onInputChange={(event, newInputValue) => {
                                                        setDepartmentValue(newInputValue);
                                                    }}
                                                    // size="small"
                                                    renderInput={(params) => <TextField required fullWidth
                                                        variant="standard" {...params} label="Departamento" 
                                                        helperText={saveAttemp && !departmentValue.length ? "Es requerido" : ""}
                                                        error={saveAttemp && !departmentValue.length}
                                                    />}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Autocomplete
                                                    disablePortal
                                                    options={jobs}
                                                    getOptionLabel={(option) =>{return option?.name ? (option.code + ' ' +option.name) : ''}}
                                                    value={selectedJob}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.name === value?.name
                                                    }
                                                    onChange={(event, newValue,reason) => {
                                                        if(newValue){
                                                            setSelectedJob(newValue)
                                                            setJobValue(newValue.name);
                                                        }
                                                    }}
                                                    inputValue={jobValue}
                                                    onInputChange={(event, newInputValue) => {
                                                        setJobValue(newInputValue);
                                                    }}
                                                    // size="small"
                                                    renderInput={(params) => <TextField required fullWidth
                                                        variant="standard" {...params} label="Puesto" 
                                                        helperText={saveAttemp && !jobValue.length ? "Es requerido" : ""}
                                                        error={saveAttemp && !jobValue.length}
                                                    />}
                                                />
                                            </Grid>
                                        </Grid>
                                        <br/>
                                        <hr/>
                                        <Typography variant="h6" gutterBottom>
                                            Cargar Documentos
                                        </Typography>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={3}>
                                                <Button fullWidth variant="contained" component="label">
                                                    {/* {btnUploadXml} */}
                                                    Constancia Situación Fiscal
                                                    <input hidden accept="image/*, application/pdf" type="file" onChange={(e) => {handleCapture(e,1)}} />
                                                </Button>
                                                {
                                                    CSFFile ?
                                                    <FormHelperText >{CSFFile.name}</FormHelperText>
                                                    : ""
                                                }
                                                {
                                                    saveAttemp && !CSFFile ?
                                                    <FormHelperText sx={{color:"red"}}>Requerido</FormHelperText>
                                                    : ""
                                                }
                                                
                                                
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Button fullWidth variant="contained" component="label">
                                                    {/* {btnUploadXml} */}
                                                    Estado de cuenta
                                                    <input hidden accept="image/*, application/pdf" type="file" onChange={(e) => {handleCapture(e,2)}} />
                                                </Button>
                                                {
                                                    edoCtaFile ?
                                                    <FormHelperText >{edoCtaFile.name}</FormHelperText>
                                                    : ""
                                                }
                                                {
                                                    saveAttemp && !edoCtaFile ?
                                                    <FormHelperText sx={{color:"red"}}>Requerido</FormHelperText>
                                                    : ""
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Button fullWidth variant="contained" component="label">
                                                    {/* {btnUploadXml} */}
                                                    INE
                                                    <input hidden accept="image/*, application/pdf" type="file" onChange={(e) => {handleCapture(e,3)}} />
                                                </Button>
                                                {
                                                    INEFile ?
                                                    <FormHelperText >{INEFile.name}</FormHelperText>
                                                    : ""
                                                }
                                                {
                                                    saveAttemp && !INEFile ?
                                                    <FormHelperText sx={{color:"red"}}>Requerido</FormHelperText>
                                                    : ""
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Button fullWidth variant="contained" component="label">
                                                    {/* {btnUploadXml} */}
                                                    Comprobante de domicilio
                                                    <input hidden accept="image/*, application/pdf" type="file" onChange={(e) => {handleCapture(e,4)}} />
                                                </Button>
                                                {
                                                    CDFile ?
                                                    <FormHelperText >{CDFile.name}</FormHelperText>
                                                    : ""
                                                }
                                                {
                                                    saveAttemp && !CDFile ?
                                                    <FormHelperText sx={{color:"red"}}>Requerido</FormHelperText>
                                                    : ""
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Button fullWidth variant="contained" component="label">
                                                    {/* {btnUploadXml} */}
                                                    Opinión de cumplimiento
                                                    <input hidden accept="image/*, application/pdf" type="file" onChange={(e) => {handleCapture(e,5)}} />
                                                </Button>
                                                {
                                                    OCFile ?
                                                    <FormHelperText >{OCFile.name}</FormHelperText>
                                                    : ""
                                                }
                                                {
                                                    saveAttemp && !OCFile ?
                                                    <FormHelperText sx={{color:"red"}}>Requerido</FormHelperText>
                                                    : ""
                                                }
                                            </Grid>
                                            {
                                                value.rfc.length == 12 
                                                ?
                                                <Fragment>
                                                    <Grid item xs={12} sm={3}>
                                                        <Button fullWidth variant="contained" component="label">
                                                            {/* {btnUploadXml} */}
                                                            Acta constitutiva
                                                            <input hidden accept="image/*, application/pdf" type="file" onChange={(e) => {handleCapture(e,6)}} />
                                                        </Button>
                                                        {
                                                            ACFile ?
                                                            <FormHelperText >{ACFile.name}</FormHelperText>
                                                            : ""
                                                        }
                                                        {
                                                            saveAttemp && !ACFile ?
                                                            <FormHelperText sx={{color:"red"}}>Requerido</FormHelperText>
                                                            : ""
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Button fullWidth variant="contained" component="label">
                                                            {/* {btnUploadXml} */}
                                                            Carta Poder
                                                            <input hidden accept="image/*, application/pdf" type="file" onChange={(e) => {handleCapture(e,7)}} />
                                                        </Button>
                                                        {
                                                            PFile ?
                                                            <FormHelperText >{PFile.name}</FormHelperText>
                                                            : ""
                                                        }
                                                        {
                                                            saveAttemp && !PFile ?
                                                            <FormHelperText sx={{color:"red"}}>Requerido</FormHelperText>
                                                            : ""
                                                        }
                                                    </Grid>
                                                </Fragment>
                                                : ""
                                            }
                                            <Grid item xs={12} sm={3}>
                                                <Button fullWidth variant="contained" component="label">
                                                    {/* {btnUploadXml} */}
                                                    Lista de Productos
                                                    <input hidden accept="image/*, application/pdf" type="file" onChange={(e) => {handleCapture(e,8)}} />
                                                </Button>
                                                {
                                                    LPFile ?
                                                    <FormHelperText >{LPFile.name}</FormHelperText>
                                                    : ""
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Button fullWidth variant="contained" component="label">
                                                    {/* {btnUploadXml} */}
                                                    Políticas de Garantías
                                                    <input hidden accept="image/*, application/pdf" type="file" onChange={(e) => {handleCapture(e,9)}} />
                                                </Button>
                                                {
                                                    PGFile ?
                                                    <FormHelperText >{PGFile.name}</FormHelperText>
                                                    : ""
                                                }
                                            </Grid>
                                            
                                        </Grid>
                                        

                                        <Grid container spacing={12} direction="row"
                                            justifyContent="center"
                                            alignItems="center">
                                                <Grid item xs={12} sm={1}></Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Button startIcon={<DownloadOutlined />} size="small" variant="text" onClick={() =>{downloadPrivacityFile()}}>Aviso de Privacidad</Button>
                                                <FormControlLabel
                                                    value="Acepto aviso de privacidad"
                                                    control={<Checkbox />}
                                                    label="Acepto aviso de privacidad"
                                                    checked={acceptTerms}
                                                    onChange={(e) =>{setAcceptTerms(e.target.checked)}}
                                                    labelPlacement="end"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} direction="row"
                                            justifyContent="center"
                                            alignItems="center">
                                            <Grid item xs={12} sm={4}>
                                                <LoadingButton
                                                    fullWidth
                                                    loading={loading}
                                                    loadingPosition="start"
                                                    startIcon={<Save />}
                                                    variant="contained"
                                                    loadingIndicator={<CircularProgress color="primary" size={16}/>}
                                                    size="large"
                                                    //disabled={!value.name.length}
                                                    onClick={(e) =>{register(e)}}
                                                >
                                                    Guardar
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                </Fragment>
                                :
                                <Grid container  sx={{ mb: 4, mt: 4 }} spacing={3} justifyContent="center" alignContent="center">
                                    <CheckCircleRounded color="success" fontSize="large"/>
                                    <Typography variant="h5">
                                        Registro exitoso
                                    </Typography>
                                    <br/>
                                    <Typography variant="h6" textAlign="center">
                                        Su registro ha sido exitoso, una vez registrado se validará la información 
                                        y se le enviará su usuario y contraseña vía correo electrónico para el uso del
                                        portal.
                                    </Typography>
                                </Grid>
                            }
                            
                        </Container>
                    </Fragment>
                : 
                <Container component="main" maxWidth="sm" sx={{ mb: 4, mt: 4 }}>
                    <h1>{response.msg}</h1>
                </Container>
            }
        </Fragment>
    )

    
}

export default ProviderRegister;