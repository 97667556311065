import './App.css';
import {Provider} from 'react-redux';
// import store from './Store';
import { BrowserRouter as Router } from 'react-router-dom';
import Routers from './Routes';

function App() {
  return (
      <Router>
        <Routers />
      </Router>
  );
}

export default App;
