import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import {useNavigate} from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { MenuItems } from "./MenuItem";
import { useDispatch, useSelector } from "react-redux";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Logout from '@mui/icons-material/Logout';
import { AccountCircle } from "@mui/icons-material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import Badge from '@mui/material/Badge';
import { UserService, UserServices } from "../../Services/UserService";
import { Tooltip } from "@mui/material";
import FloatAlert from "../Alert";
import logo from '../../logo2.png'
import logoRF from '../../logoRF2.png'

const MenuComponent = ({children, permissionName}) =>{
    const dispatch = useDispatch();
    const {menu} = useSelector(store => store.componentReducer);
    const {permissions} = useSelector(store => store.userReducer);
    const history = useNavigate();
    const drawerWidth = 240;
    let [hasPermission,setHasPermission] = useState(false);
    let roleId = window.sessionStorage.getItem("userRoleId");
    
    useLayoutEffect(() =>{
      UserService.validateToken((response,error) =>{
        if(response && response.success){
          const hasPermission = permissionName ? permissions.some(element => element.applicationOperation.name == permissionName) : true;
          setHasPermission(hasPermission);
          if(!hasPermission){
            dispatch({type: "SELECTED_MENU_ITEM",idx:"menu",value: {selectedItem: 0,name:"Inicio"}});
            history('/portal/home');
          }
          // let params = {
          //   filter: `{"where":{"roleId":${Number(roleId)}}, "include":["applicationOperation"]}`
          // }
          // UserService.getApplicationAuthorizations(params,(response,error) =>{
          //   if(response){
          //     dispatch({type: "SET_PERMISSIONS",idx:"permissions",value: response});
          //     const hasPermission = permissionName ? response.some(element => element.applicationOperation.name == permissionName) : true;
          //     setHasPermission(hasPermission);
          //     if(!hasPermission){
          //       dispatch({type: "SELECTED_MENU_ITEM",idx:"menu",value: {selectedItem: 0,name:"Inicio"}});
          //       history('/portal/home');
          //     }
          //   }
          // });
        }
        else if(error && error.statusCode == 401){
          let props = {open: true,
            severity: 'error',
            message: error.message}
          dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
          logout();
        }
      })
      // let userId = window.sessionStorage.getItem('userId');
      
      

    },[])

    // useEffect(() =>{
      
    //   // let userId = window.sessionStorage.getItem('userId');
    //   let params = {
    //     filter: `{"where":{"roleId":${Number(roleId)}}, "include":["applicationOperation"]}`
    //   }
    //   console.log("rol",params);
    //   UserService.getApplicationAuthorizations(params,(response,error) =>{
    //     if(response){
    //       dispatch({type: "SET_PERMISSIONS",idx:"permissions",value: response});
    //       const hasPermission = permissionName ? response.some(element => element.applicationOperation.name == permissionName) : true;
    //       setHasPermission(hasPermission);
    //       console.log("haspermision",hasPermission);
    //       if(!hasPermission){
    //         dispatch({type: "SELECTED_MENU_ITEM",idx:"menu",value: {selectedItem: 0,name:"Inicio"}});
    //         history('/portal/home');
    //       }
    //     }
    //   });
    // },[]);


  const [isMenuOpen,setIsMenuOpen] = useState(false)
  const [isMobileMenuOpen,setIsMobileMenuOpen] = useState(false);

  const handleProfileMenuOpen = (event) => {
    setIsMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setIsMobileMenuOpen(false);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = () => {
    setIsMobileMenuOpen(true);
  };

  const menuId = 'primary-search-account-menu';

  const mobileMenuId = 'primary-search-account-menu-mobile';

    

    const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    });

    const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    });

    const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    }));

    const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    }));

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
    );
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const selectItem = (id,url,name) =>{
        dispatch({type: "SELECTED_MENU_ITEM",idx:"menu",value: {selectedItem: id,name:name}});
        navigate(url);
    }

    const navigate = (url) =>{
        history(url);
    }



    const logout = () =>{
        dispatch({type: "SELECTED_MENU_ITEM",idx:"menu",value: {selectedItem: 0,name:"Inicio"}});
        window.sessionStorage.removeItem("usrToken");
        window.sessionStorage.removeItem("userId");
        history("/portal/login");
    }

    const renderMenu = (
        <Menu
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={menuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
        {/* <MenuItem>
            <ListItemIcon>
                <AccountCircle fontSize="small" />
            </ListItemIcon>
            Mi Cuenta
        </MenuItem>
            <Divider /> */}
            {/*<MenuItem>
            <ListItemIcon>
                <PersonAdd fontSize="small" />
            </ListItemIcon>
            Add another account
            </MenuItem>
            <MenuItem>
            <ListItemIcon>
                <Settings fontSize="small" />
            </ListItemIcon>
            Settings
        </MenuItem>*/}
            <MenuItem onClick={() => logout()}>
            <ListItemIcon>
                <Logout fontSize="small" />
            </ListItemIcon>
            Cerrar sesión
            </MenuItem>
        </Menu>
      );
    
      const renderMobileMenu = (
        <Menu
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={mobileMenuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}
        >
          {/* <MenuItem>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <p>Notificaciones</p>
          </MenuItem> */}
          <MenuItem onClick={() => logout()}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
            >
              <Logout fontSize="small" />
            </IconButton>
            <p>Cerrar Sesión</p>
          </MenuItem>
        </Menu>
      );
    return (
        <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <FloatAlert/>
        <AppBar position="fixed" open={open}>
            <Toolbar>
              <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                  marginRight: 5,
                  ...(open && { display: 'none' }),
                  }}
              >
                  <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                  {menu.name?.length ? menu.name : permissionName}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <img src={logo} alt="Logo" height={"100px"} width={"100px"}/>
              <Box sx={{ flexGrow: 1 }} />
              <img src={logoRF} alt="Logo" height={"100px"} width={"100px"}/>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  
                  {/* <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                  >
                  <Badge badgeContent={17} color="error">
                      <NotificationsIcon />
                  </Badge>
                  </IconButton> */}
                  <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                  >
                  <AccountCircle />
                  </IconButton>
              </Box>
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </Toolbar>
        </AppBar>
          
        {renderMobileMenu}
      {renderMenu}
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
            </DrawerHeader>
            <Divider />
            <List sx={{paddingTop: '40px'}}>
            {MenuItems.map((menuItem, index) => (
              <Fragment key={menuItem.id}>
                {
                    ( !menuItem.permissionName || (menuItem.permissionName && permissions.find((permission) => permission.applicationOperation.name == menuItem.permissionName))) ?
                    <Tooltip placement="right" title={!open ? menuItem.name : ""}>
                      <ListItemButton  
                        selected={menu.selectedItem === menuItem.id}
                        sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        }}
                        onClick={() =>{selectItem(menuItem.id,menuItem.url,menuItem.name)}}
                    >
                        <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                        >
                        {menuItem.icon}
                        </ListItemIcon>
                        <ListItemText primary={menuItem.name} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton></Tooltip>: ""
                }
                
              </Fragment>
            ))}
            </List>
            {/* <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Nested List Items
        </ListSubheader>
      }
    >
      <ListItemButton>
        <ListItemIcon>
          <SendIcon />
        </ListItemIcon>
        <ListItemText primary="Sent mail" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <DraftsIcon />
        </ListItemIcon>
        <ListItemText primary="Drafts" />
      </ListItemButton>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary="Inbox" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Starred" />
          </ListItemButton>
        </List>
      </Collapse>
    </List> */}
            <Divider />
        </Drawer>
          {
            hasPermission ? 
              <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                <br/>
                {children}
              </Box>
              : ''
          }
      </Box>
    );
}

export default MenuComponent;