import API_ROUTES from "../Routes/APIRoutes";

export const OrderService = {


    getOrders: (params,callback) => {
        fetch(API_ROUTES.getOrders,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
            body: JSON.stringify(params) 
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
            })
            
        })
        .catch((err)=>callback(null, err));
    },
    uploadProviderInvoice: (formData,sapProviderId, callback) => {
        fetch(API_ROUTES.uploadProviderInvoice + sapProviderId,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
            body: formData 
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
            })
            
        })
        .catch((err)=>callback(null, err));
    },

}