import React, { Fragment, useLayoutEffect, useState } from "react";
import Menu from "../../Components/Menu/Menu";
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, IconButton, Input, InputAdornment, InputLabel, TextField } from "@mui/material";
import { UserService } from "../../Services/UserService";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import FloatAlert from "../../Components/Alert";
import { useDispatch, useSelector } from "react-redux";

const Home = () =>{

    const dispatch = useDispatch();

    const initialValue = {
        password: "",
        confirmPassword: ""
    }
    const[value,setValue] = useState(initialValue);
    const[open,setOpen] = useState(false);
    const[loadingBackDrop,setLoadingBackDrop] = useState(false);
    const[showPassword,setShowPassword] = useState(false);
    const[showConfirmPassword,setShowConfirmPassword] = useState(false);
    // const [name,setName] = useState("");
    let userId = window.sessionStorage.getItem("userId");
    const {changePassword,name} = useSelector(store => store.userReducer);

    // useLayoutEffect(() =>{
    //     setLoadingBackDrop(true);
    //     UserService.getUserById({userId,filter: `{"include":["person"]}`},(response,error) =>{
    //         setLoadingBackDrop(false);
    //         if(response && !error){
    //             setName(response.person?.fullName);
    //             setOpen(response.changePassword);
    //         }
    //     })


    // },[])

    const handleClose = (e,reason) =>{
        if(!reason){
            setOpen(false);
        }
    }

    const updatePassword = () =>{
        setLoadingBackDrop(true);
        let params = {
            userId: Number(window.sessionStorage.getItem("userId")),
            password: value.password,
            isReset: false
        }
        UserService.updatePassword(params,(response,error) => {
            dispatch({type: "UPDATE_USER_DATA",idx:"changePassword",value: false});
            setLoadingBackDrop(false);
            if(response && !error){
                setOpen(false);
                let props = {open: true,
                    severity: 'success',
                    message: `Actualización exitosa`};
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }
            else{
                let props = {open: true,
                    severity: 'error',
                    message: error.message}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }
        })

    }

    return(
        <Fragment>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingBackDrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <FloatAlert/>
            <Menu>
                <h1>Bienvenido {name}</h1>
            </Menu>
            <Dialog open={changePassword} onClose={handleClose}>
                <DialogTitle>Actualización de contraseña</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Por seguridad es necesario actualizar su contraseña.
                </DialogContentText>
                {/* <TextField
                    autoFocus
                    value={value.password}
                    onChange={(e) =>{setValue({...value,password: e.target.value})}}
                    
                    label="Nueva contraseña"
                    type="password"
                    fullWidth
                    variant="standard"
                    
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>{setShowPassword(!showPassword)}}
                            onMouseDown={() =>{setShowPassword(!showPassword)}}
                            edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <TextField
                    value={value.confirmPassword}
                    onChange={(e) =>{setValue({...value,confirmPassword: e.target.value})}}
                    
                    label="Confirmar Contraseña"
                    type={showConfirmPassword ? 'text' : 'password'}
                    fullWidth
                    variant="standard"
                    helperText={value.password !== value.confirmPassword ? "Las contraseñas no coinciden" : ""}
                    error={value.password !== value.confirmPassword}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>{setShowConfirmPassword(!showConfirmPassword)}}
                            onMouseDown={() =>{setShowConfirmPassword(!showConfirmPassword)}}
                            edge="end"
                            >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                /> */}
                <br/>
                <InputLabel htmlFor="standard-adornment-password">Nueva contraseña</InputLabel>
                <Input
                    fullWidth
                    id="standard-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    value={value.password}
                    onChange={(e) =>{setValue({...value,password: e.target.value})}}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>{setShowPassword(!showPassword)}}
                            onMouseDown={() =>{setShowPassword(!showPassword)}}
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                />
                <br/><br/>
                <InputLabel htmlFor="confirm-password">Confirmar contraseña</InputLabel>
                <Input
                    fullWidth
                    id="confirm-password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={value.confirmPassword}
                    onChange={(e) =>{setValue({...value,confirmPassword: e.target.value})}}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>{setShowConfirmPassword(!showConfirmPassword)}}
                            onMouseDown={() =>{setShowConfirmPassword(!showConfirmPassword)}}
                        >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                />
                <FormHelperText sx={{color:"red"}} id="confirm-password">{value.password !== value.confirmPassword ? "Las contraseñas no coinciden" : ""}</FormHelperText>
                </DialogContent>
                <DialogActions>
                {/* <Button onClick={handleClose}>Cancelar</Button> */}
                <Button onClick={updatePassword} disabled={((value.password !== value.confirmPassword) || !(value.password.length || value.confirmPassword.length))} >Actualizar</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )

}

export default Home;