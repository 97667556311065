import API_ROUTES from "../Routes/APIRoutes";

export const CatalogService = {

    getCatalog: (params,callback) =>{
        let filter = params.filter;
        fetch(params.apiRoute+`?filter=${encodeURI(filter)}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            // headers: {
            //     'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            // },
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                callback(null,response.error);
                else
                    callback(response);
                })
                
            })
        .catch((err)=>callback(null, err));
    }
}