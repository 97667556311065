const initialState = {
    orderColumns:{
        
    },
    
}

const dataTableReducer = (state = initialState, action) => {
    switch(action.type){
        case 'UPDATE_ORDER_COLUMS':
            state = {...state};
            state[action.idx] = action.value;
            return state;
        default:
            return state;
    }
}

export default dataTableReducer;