import React, { Fragment, useEffect, useState } from "react";
import MenuComponent from "../../Components/Menu/Menu";
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import FloatAlert from "../../Components/Alert";
import DataTable from "../../Components/DataTable/DataTable";
import { AddOutlined, CancelOutlined, CloseOutlined, Save, Visibility, WindowSharp } from "@mui/icons-material";
import { OperationServices } from "../../Services/OperationService";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";

const ProviderInvitation = () =>{
    
    const userId = Number(window.sessionStorage.getItem("userId"));
    const rfcRegex = /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const initialValue = {
        providerName: "",
        email: "",
        rfc: "",
        userId: userId
    }
    const [value,setValue] = useState(initialValue);
    const [loadingBackDrop,setLoadingBackDrop] = useState(false);
    const [loading,setLoading] = useState(false);
    const [open,setOpen] = useState(false);
    const [invitations,setInvitations] = useState([]);
    const dispatch = useDispatch();
    const [saveAttemp,setSaveAttemp] = useState(false);

    useEffect(() =>{
        getInvitations();

    },[]);

    const getInvitations = () =>{
        setLoadingBackDrop(true);
        OperationServices.getProviderInvitations({filter:`{"where":{"userId": ${userId}}, "order": "creationDate DESC"}`}, (response,error) =>{
            setLoadingBackDrop(false);
            if(response && response.length){
                setInvitations(response);
            }
        })
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'providerName', headerName: 'Proveedor', width: 200},
        { field: 'rfc', headerName: 'RFC', width: 200},
        { field: 'email', headerName: 'Correo', width: 200},
        { field: 'creationDate', headerName: 'Fecha', width: 170,
            valueGetter: (params) =>{
                return params.row.creationDate.substring(0,19).replace('T',' ');
                // return moment.tz(params.row.creationDate,'America/Mexico_City').format("YYYY-MM-DD HH:mm");
            },
        },
        { field: 'expirationDate', headerName: 'Fecha Expiración', width: 170,
            valueGetter: (params) =>{
                return params.row.expirationDate.substring(0,19).replace('T',' ');
                // return moment.tz(params.row.expirationDate,'America/Mexico_City').format("YYYY-MM-DD HH:mm");
            },
        },
        { field: 'status', headerName: 'Estatus', width: 170,
            valueGetter: (params) =>{
                let status = "";
                switch(params.row.status){
                    case 1:
                        status = "ENVIADA"
                        break;
                    case 2:
                        status = "ACEPTADA"
                        break;
                    case 4:
                        status = "EXPIRADA"
                        break;
                    case 8:
                        status = "RECHAZADA"
                        break;
                    case 0:
                        status = "CANCELADA"
                        break;
                }
                return status
            },
        },
        {
            field: '',
            headerName: 'Opciones',
            description: 'This column has a value getter and is not sortable.',
            width: 160,
            renderCell: (cellValues) => {
                return (
                    <Fragment>
                        {
                            cellValues.row.status == 1 ? 
                            <Tooltip title="Cancelar Invitación">
                                <IconButton aria-label="edit" 
                                    onClick={(event) => {
                                        cancelInvitation(event,cellValues);
                                    }}
                                    >
                                        <CancelOutlined />
                                </IconButton>
                            </Tooltip>
                            : ""
                        }
                         
                        
                    </Fragment>
                );
            }
            
        },
    ];

    const getClassName = (params) => {
        if (params.field === "status") {
          return params.value === "ACEPTADA" ? "cell-green" : params.value == 'ENVIADA' ? "cell-yellow" : params.value == 'EXPIRADA' ? "cell-orange" : "cell-red";
        }
        return "";
    }

    const newInvitation = () =>{
        setSaveAttemp(false);
        setOpen(true);
    }

    const handleClose = (e,reason) =>{
        if(!reason){
            setOpen(false);
            setValue(initialValue);
        }
    }

    const cancelInvitation = (e,cellValues) =>{
        setLoadingBackDrop(true);
        let params = {
            id: cellValues.row.id,
            body: {
                status: 0,
            }
        };
        OperationServices.updateProviderInvitation(params,(response,error) =>{
            setLoadingBackDrop(false);
            if(response && response.status == 204){
                let props = {open: true,
                    severity: 'success',
                    message: `La invitación ha sido cancelada.`};
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                getInvitations();
            }
            else{
                let props = {open: true,
                    severity: 'error',
                    message: error.message}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }

        })

    }

    const addInvitation = () =>{
        setSaveAttemp(true);
        if(value.providerName.length && value.email.length && rfcRegex.test(value.rfc) && emailRegex.test(value.email)){
            
            setLoading(true);
            let params = value;
            OperationServices.addProviderInvitation(params,(response,error) =>{
                setLoading(false);
                if(response && !error){
                    let props = {open: true,
                        severity: 'success',
                        message: `Registro agregado correctamente.`};
                    dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                    handleClose();
                    getInvitations();
                }
                else{
                    let props = {open: true,
                        severity: 'error',
                        message: error.message}
                    dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                }

            });
        }
    }

    return (
        <Fragment>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingBackDrop}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
            <FloatAlert/>

            <MenuComponent permissionName={"Invitaciones Proveedores"}>
                <DataTable columns={columns} rows={invitations} getClassName={getClassName} />
                <Box sx={{flexGrow: 1}}></Box>
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                    <Fab  color="primary" aria-label="add" onClick={() =>{newInvitation()}}
                        sx={{position: 'fixed',bottom: 16, right: 16,}}>
                        <AddOutlined />
                    </Fab>
                </Box>
            </MenuComponent>
            <Dialog disableEscapeKeyDown={true} open={open} onClose={handleClose}>
                <DialogTitle>Nueva Invitación</DialogTitle>
                <DialogContent>
                {/* <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& > :not(style)': { m: 1 }
                }}>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off">

                        <TextField fullWidth variant="standard" required label="Razón social" value={value.providerName} onChange={(e) =>{setValue({...value,providerName:e.target.value})}}/>
                        <br/>
                        <TextField fullWidth variant="standard" required label="Correo" value={value.email} onChange={(e) =>{setValue({...value,email:e.target.value})}}/>
                    </Box>
                    
                </Box> */}
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <TextField fullWidth variant="standard" 
                        required label="Razón social" 
                        value={value.providerName} 
                        helperText={saveAttemp && !value.providerName.length ? "Es requerido" : ""}
                        error={saveAttemp && (!value.providerName.length)}
                        onChange={(e) =>{setValue({...value,providerName:e.target.value})}}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField fullWidth variant="standard" 
                        inputProps={{ minLength: 11, maxLength: 13 }} 
                        helperText={saveAttemp && !rfcRegex.test(value.rfc) ? "El RFC es inválido" : ""}
                        error={saveAttemp && !rfcRegex.test(value.rfc)}
                        required label="RFC" value={value.rfc} onChange={(e) =>{setValue({...value,rfc:e.target.value})}}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField fullWidth variant="standard" 
                        required label="Correo" value={value.email} 
                        helperText={saveAttemp ? (!value.email.length ? "Es requerido" : !emailRegex.test(value.email) ? "Formato no válido" : "") : ""}
                        error={saveAttemp && (!value.email.length || !emailRegex.test(value.email))}
                        onChange={(e) =>{setValue({...value,email:e.target.value})}}/>
                    </Grid>
                </Grid>
                </DialogContent>
                <DialogActions>
                <Button disabled={loading} startIcon={<CloseOutlined />} onClick={handleClose} size="small" variant="outlined">Cancelar</Button>
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<Save />}
                    variant="outlined"
                    loadingIndicator={<CircularProgress color="primary" size={16}/>}
                    size="small"
                    //disabled={!value.providerName.length && !value.email.length}
                    onClick={addInvitation}
                >
                    Guardar
                </LoadingButton>
                </DialogActions>
            </Dialog>
        </Fragment>
    )

}

export default ProviderInvitation;