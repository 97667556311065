import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const ConfirmAlert = (props) => {

    const dispatch = useDispatch();
    let {confirmAlert} = useSelector(store => store.componentReducer);

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    return (
        <div>
          <Dialog
            open={props.open}
            //TransitionComponent={Transition}
            keepMounted
            onClose={props.onclose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {props.context}
              </DialogContentText>
              {props.check}
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onclose}>Cancelar</Button>
              <Button onClick={props.onclick}>Aceptar</Button>
            </DialogActions>
          </Dialog>
        </div>
      );

}

export default ConfirmAlert;