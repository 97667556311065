import { LockOutlined, LockResetOutlined } from "@mui/icons-material";
import { Avatar, Backdrop, Box, Button, CircularProgress, Container, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link, TextField, ThemeProvider, Typography, createTheme } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserService } from "../../Services/UserService";
import FloatAlert from "../../Components/Alert";


const RecoverPassword = () =>{

    const history = useNavigate();
    let dispatch = useDispatch();

    let initialValue = {
        username: '',
        password: ''
    }

    const [value, setValue] = useState(initialValue);
    
    let [loading, setLoading] = useState(false);
    let [open, setOpen] = useState(false);

    function Copyright(props) {
        return (
          <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
              Portal de Proveedores
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        );
    }

    const handleClose = (e,reason) =>{
        if(!reason){
            history('/portal/login');
        }
    }

      
    const theme = createTheme();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        let params = {
            user: value.username
        };
        UserService.recoverPass(params,(response,error) =>{
            setLoading(false);
            if(error){
                let props = {open: true,
                    severity: 'error',
                    message: error.message}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}})
            }
            else{
                setOpen(true);
            }
        });
    };


    return (
        <ThemeProvider theme={theme}>
            <FloatAlert/>
    
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
          </Backdrop>
            <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockResetOutlined />
                </Avatar>
                <Typography component="h1" variant="h5">
                Recuperar Contraseña
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Usuario"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={value.username}
                    onChange={(e) =>{setValue({...value, username: e.target.value})}}
                />
                
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={!(value.username.length > 0)}
                    >
                        Recuperar cuenta
                    </Button>
                    {/* Se ha enviado un correo a su cuenta con la información necesaria para realizar la recuperación de su contraseña. Cualquier duda o aclaración contacte a nuestro equipo con gusto le atenderemos.  */}
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
            <Dialog disableEscapeKeyDown={true} open={open} onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Recuperar Cuenta
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Se ha enviado un correo a su cuenta con la información necesaria para realizar la recuperación de su contraseña. Cualquier duda o aclaración contacte a nuestro equipo con gusto le atenderemos. 
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Aceptar</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
        );

}

export default RecoverPassword;