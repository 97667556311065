import { combineReducers,createStore } from 'redux';
// import { configureStore } from '@reduxjs/toolkit';
import userReducer from './Reducers/userReducer';
import componentReducer from './Reducers/componentReducer';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import dataTableReducer from './Reducers/dataTableReduces';

// const reducer = combineReducers({
//   userReducer,
//   componentReducer
// })
// const store = configureStore({
//   reducer
// })
// export default store;

const componentPersistConfig = {
  key: 'components',
  storage,
  keyPrefix: 'redux-component',
  whitelist: ['menu','confirmAlert']
};

const dataTablePersistConfig = {
  key: 'data-table-storage',
  storage,
  // keyPrefix: 'data-table-storage'
};

function configureStore(initialState = {}) {
  const reducer = combineReducers({
    userReducer,
    // componentReducer,
    componentReducer: persistReducer(componentPersistConfig, componentReducer),
    dataTableReducer: persistReducer(dataTablePersistConfig, dataTableReducer),
  });

  const store = createStore(persistReducer({
    key: "root",
    debug: true,
    storage,
    blacklist: ['componentReducer','dataTableReducer'],
  }, reducer), initialState);

  // console.log("initialState", store.getState());

  const persistor = persistStore(store, null, () => {
    // if you want to get restoredState
    // console.log("restoredState", store.getState());
  });

  return { store, persistor };
  
}

export default configureStore;