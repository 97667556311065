import React from "react";
import { Fragment } from "react";
import MenuComponent from "../../Components/Menu/Menu";
import DataTable from "../../Components/DataTable/DataTable";
import { useState } from "react";
import { Autocomplete, Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormHelperText, Grid, IconButton, Input, InputAdornment, InputLabel, TextField, Tooltip } from "@mui/material";
import { Add, CheckBoxOutlineBlankOutlined, CheckBoxOutlined, CloseOutlined, Edit, EditOutlined, LockResetOutlined, Save, Visibility, VisibilityOff } from "@mui/icons-material";
import { UserService } from "../../Services/UserService";
import { LoadingButton } from "@mui/lab";
import FloatAlert from "../../Components/Alert";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const UserIndex = () =>{

    let initialValue = {
        id: 0,
        fullName: '',
        email: '',
        phoneNumber: '',
        password: '',
        confirmPassword: ''
    }

    let initialResetValue = {
        userId: 0,
        password: '',
        confirmPassword: ''
    }

    const [users,setUsers] = useState([]);
    const [roles,setRoles] = useState([]);
    const [selectedRol,setSelectedRol] = useState({});
    const [rolValue,setRolValue] = useState('');
    const [editing,setEditing] = useState(false);
    const [value,setValue] = useState(initialValue);
    const [resetValue,setResetValue] = useState(initialResetValue);
    const [open,setOpen] = useState(false);
    const [openResetPass,setOpenResetPass] = useState(false);
    const [loadingBackDrop,setLoadingBackDrop] = useState(false);
    const [loading,setLoading] = useState(false);
    const [showPassword,setShowPassword] = useState(false);
    const [saveAttemp,setSaveAttemp] = useState(false);
    const[showResetPassword,setShowResetPassword] = useState(false);
    const[showResetConfirmPassword,setShowResetConfirmPassword] = useState(false);
    const dispatch = useDispatch();
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    useEffect(() =>{
        getUsers();
        getRoles();
    },[])

    const getUsers = () =>{
        setLoadingBackDrop(true);
        let params = {
            filter: `{"include": ["person","role"]}`
        }
        UserService.getUsers(params,(response,error) =>{
            setLoadingBackDrop(false);
            if(response && response.length)
                setUsers(response);
        })
    }

    let getRoles = () =>{
        let params = {
            filter: `{"where": {"status" : 1}}`
        }
        UserService.getRoles(params,(response,error) =>{
            if(response && response.length){
                setRoles(response);
            }
        });
    }

    let updateUserData = (event,cellValues) =>{
        let user = cellValues.row;
      let data = {
          id: user.id,
          fullName: user.person?.fullName,
          phoneNumber: user.person?.phoneNumber,
          email: user.email
      }
      setSelectedRol(user.role ? user.role : {});
      setEditing(true);
      setValue(data);
      setOpen(true);
    }
  
    let changeUserStatus = (e,cellValues) =>{
        setLoadingBackDrop(true);
        let params ={
            id: cellValues.row.id,
            body:{
                status: cellValues.row.status == 1 ? 0 : 1
            }
        }
        UserService.changeUserStatus(params,(response,error) =>{
            setLoadingBackDrop(false);
            if(response && response.status === 204){
                let props = {open: true,
                    severity: 'success',
                    message: 'Usuario actualizado correctamente.'};
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                getUsers();
                handleClose();
            }
            else{
                let props = {open: true,
                    severity: 'error',
                    message: error.message}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }
        })
    
  
  }

  const getClassName = (params) => {
        if (params.field === "status") {
        return params.value === "ACTIVO" ? "active-cell" : "inactive-cell";
        }
        return "";
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'fullName', headerName: 'Nombre', width: 300,
            valueGetter: (params) =>{
                return params.row.person?.fullName
            },
        },
        { field: 'email', headerName: 'Usuario', width: 250},
        { field: 'phoneNumber', headerName: 'Teléfono', width: 130,
            valueGetter: (params) =>{
                return params.row.person?.phoneNumber
            },
        },
        { field: 'role', headerName: 'Rol', width: 130,
            valueGetter: (params) =>{
                return params.row.role?.name
            },
        },
        { field: 'status', headerName: 'Estatus', width: 130,
            valueGetter: (params) =>{
                return params.row.status == 1 ? "ACTIVO" : "INACTIVO";
            },
        },
        {
            field: '',
            headerName: 'Opciones',
            description: 'This column has a value getter and is not sortable.',
            width: 160,
            renderCell: (cellValues) => {
                return (
                    <React.Fragment>
                         <Tooltip title="Editar">
                            <IconButton aria-label="edit" 
                                onClick={(event) => {
                                    updateUserData(event, cellValues);
                                }}
                                >
                                    <EditOutlined />
                            </IconButton>
                        </Tooltip>
                        {
                            cellValues.row.status == 0 ?
                            <Tooltip title="Habilitar">
                            <IconButton aria-label="Habilitar" 
                                onClick={(event) => {
                                    changeUserStatus(event, cellValues);
                                }}
                                >
                                    < CheckBoxOutlineBlankOutlined/>
                            </IconButton>
                            </Tooltip>
                            :
                            <Tooltip title="Deshabilitar">
                                <IconButton aria-label="Deshabilitar" 
                                    onClick={(event) => {
                                        changeUserStatus(event, cellValues);
                                    }}
                                    >
                                        < CheckBoxOutlined/>
                                </IconButton>
                            </Tooltip>
                        }
                        <Tooltip title="Restablecer contraseña">
                            <IconButton aria-label="edit" 
                                onClick={(event) => {
                                    setResetValue({...resetValue,userId: cellValues.row.id});
                                    setOpenResetPass(true);
                                }}
                                >
                                    <LockResetOutlined />
                            </IconButton>
                        </Tooltip>
                        
                        
                        
                    </React.Fragment>
                );
            }
            
        },
    ];

    const handleClickOpen = () =>{
        setEditing(false);
        setOpen(true);
    }

    const handleClose = (e,reason) =>{
        if(!reason){
            setSaveAttemp(false);
            setOpen(false);
            setValue(initialValue);
            setSelectedRol({});
        }
    }

    const handleCloseReset = (e,reason) =>{
        if(!reason){
            setOpenResetPass(false);
            setShowResetConfirmPassword(false);
            setShowResetPassword(false);
            setResetValue(initialResetValue);
        }
    }

    const addUser = (e) =>{
        setSaveAttemp(true);
        e.preventDefault();
        if(validateFields()){
            setLoading(true);
            let params = value;
            params.roleId = selectedRol.id;
            delete params.confirmPassword;
            if(!editing){
                delete params.id;
                UserService.addUser(params, (response,error) =>{
                    setLoading(false);
                    if(error){
                        let props = {open: true,
                            severity: 'error',
                            message: error.message}
                        dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                    }
                    else if(response.id){
                        let props = {open: true,
                            severity: 'success',
                            message: 'Usuario agregado correctamente.'};
                        dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                        getUsers();
                        handleClose();
                    }
                })
                
            }
            else{
                delete params.password;
                let parameters = {
                    id: value.id,
                    body: params
                }
                UserService.updateUser(parameters, (response,error) =>{
                    setLoading(false);
                    if(response && response.status === 204){
                        let props = {open: true,
                            severity: 'success',
                            message: 'Usuario actualizado correctamente.'};
                        dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                        getUsers();
                        handleClose();
                    }
                    else{
                        let props = {open: true,
                            severity: 'error',
                            message: error.message}
                        dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                    }
                })
        
            }
        }
        else{
            let props = {open: true,
                severity: 'warning',
                message: `Es necesario llenar los campos requeridos.`};
            dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
        }
      }

    const validateFields = () =>{
        return ((value.fullName.length && value.email.length && regex.test(value.email) && value.phoneNumber.length) && (!editing ? value.password.length : true))
    }

    const updatePassword = () =>{
        setLoadingBackDrop(true);
        let params = {
            userId: resetValue.userId,
            password: resetValue.password,
            isReset: true,
        }
        UserService.updatePassword(params,(response,error) => {
            setLoadingBackDrop(false);
            if(response && !error){
                setOpenResetPass(false);
                let props = {open: true,
                    severity: 'success',
                    message: `Contraseña restablecida`};
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }
            else{
                let props = {open: true,
                    severity: 'error',
                    message: error.message}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }
        })

    }

    return(
        <Fragment>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingBackDrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <FloatAlert/>
            <MenuComponent permissionName={"Usuarios"}>
                <DataTable columns={columns} rows={users} getClassName={getClassName}/>
                <Box sx={{flexGrow: 1}}></Box>
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                    <Fab  color="primary" aria-label="add" onClick={() =>{(handleClickOpen())}}
                        sx={{position: 'fixed',bottom: 16, right: 16,}}>
                        <Add />
                    </Fab>
                    
                </Box>
            </MenuComponent>
            <Dialog disableEscapeKeyDown={true} open={open} onClose={handleClose}>
                <DialogTitle>{editing ? "Editar" : "Nuevo"} Usuario</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth variant="standard" required 
                                label="Nombre Completo" value={value.fullName} 
                                onChange={(e) =>{setValue({...value,fullName:e.target.value})}}
                                helperText={saveAttemp && !value.fullName.length ? "Es requerido" : ""}
                                error={saveAttemp && !value.fullName.length}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField  fullWidth variant="standard" 
                                required label="Correo" value={value.email} 
                                onChange={(e) =>{setValue({...value,email:e.target.value})}}
                                // helperText={value.email.length ? !regex.test(value.email) ? "Formato no válido" : "" : ""}
                                helperText={saveAttemp && !value.email.length ? "Es requerido" : !regex.test(value.email) ? "Formato no válido" : ""}
                                error={saveAttemp && (!value.email.length || !regex.test(value.email))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth variant="standard" 
                                inputProps={{ minLength: 10, maxLength: 10 }} 
                                required label="Teléfono" value={value.phoneNumber} 
                                onChange={(e) =>{setValue({...value,phoneNumber:e.target.value})}}
                                helperText={saveAttemp && !value.phoneNumber.length ? "Es requerido" : ""}
                                error={saveAttemp && !value.phoneNumber.length}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                disablePortal
                                options={roles}
                                getOptionLabel={(option) =>{return option?.name ? option.name : ''}}
                                value={selectedRol}
                                isOptionEqualToValue={(option, value) =>
                                    option.name === value?.name
                                }
                                onChange={(event, newValue,reason) => {
                                    if(newValue){
                                        setSelectedRol(newValue)
                                        setRolValue(newValue.name);
                                    }
                                }}
                                inputValue={rolValue}
                                onInputChange={(event, newInputValue) => {
                                    setRolValue(newInputValue);
                                }}
                                // size="small"
                                renderInput={(params) => <TextField required fullWidth
                                    variant="standard" {...params} label="Rol" 
                                    helperText={saveAttemp && !rolValue.length ? "Es requerido" : ""}
                                    error={saveAttemp && !rolValue.length}
                                />}
                            />
                        </Grid>
                        {
                            !editing ?
                            <Grid item xs={12} sm={6}>
                                <TextField type="password" fullWidth variant="standard" 
                                    required label="Contraseña" value={value.password} 
                                    onChange={(e) =>{setValue({...value,password:e.target.value})}}
                                    helperText={saveAttemp && !value.password.length ? "Es requerido" : ""}
                                    error={saveAttemp && !value.password.length}
                                />
                            </Grid>
                            : ""
                        }
                        
                    </Grid>
                </DialogContent>
                <DialogActions>
                <Button disabled={loading} startIcon={<CloseOutlined />} onClick={handleClose} size="small" variant="outlined">Cancelar</Button>
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<Save />}
                    variant="outlined"
                    loadingIndicator={<CircularProgress color="primary" size={16}/>}
                    size="small"
                    // disabled={!validateFields()}
                    onClick={addUser}
                >
                    Guardar
                </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog open={openResetPass} onClose={handleCloseReset}>
                <DialogTitle>Restablecer contraseña</DialogTitle>
                <DialogContent>
                {/* <DialogContentText>
                    Por seguridad es necesario actualizar su contraseña.
                </DialogContentText> */}
                <br/>
                <InputLabel htmlFor="standard-adornment-password">Nueva contraseña</InputLabel>
                <Input
                    fullWidth
                    id="standard-adornment-password"
                    type={showResetPassword ? 'text' : 'password'}
                    value={resetValue.password}
                    onChange={(e) =>{setResetValue({...resetValue,password: e.target.value})}}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>{setShowResetPassword(!showResetPassword)}}
                            onMouseDown={() =>{setShowResetPassword(!showResetPassword)}}
                        >
                        {showResetPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                />
                <br/><br/>
                <InputLabel htmlFor="confirm-password">Confirmar contraseña</InputLabel>
                <Input
                    fullWidth
                    id="confirm-password"
                    type={showResetConfirmPassword ? 'text' : 'password'}
                    value={resetValue.confirmPassword}
                    onChange={(e) =>{setResetValue({...resetValue,confirmPassword: e.target.value})}}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>{setShowResetConfirmPassword(!showResetConfirmPassword)}}
                            onMouseDown={() =>{setShowResetConfirmPassword(!showResetConfirmPassword)}}
                        >
                        {showResetConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                />
                <FormHelperText sx={{color:"red"}} id="confirm-password">{resetValue.password !== resetValue.confirmPassword ? "Las contraseñas no coinciden" : ""}</FormHelperText>
                </DialogContent>
                <DialogActions>
                {/* <Button onClick={handleClose}>Cancelar</Button> */}
                <Button onClick={handleCloseReset} >Cancelar</Button>
                <Button onClick={updatePassword} disabled={((resetValue.password !== resetValue.confirmPassword) || !(resetValue.password.length || resetValue.confirmPassword.length))} >Restablecer</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default UserIndex;